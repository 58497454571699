export const dataCuentaPublicaNew = [
  {
    id: 1,
    year: "2014",
    title: "Cuenta Pública 2014",
    link: "https://itetlax.org.mx/assets/pdf/transparencia/infContable/cuentaPub/1.pdf",
  },
  {
    id: 2,
    year: "2015",
    title: "Cuenta Pública 2015",
    link: "https://itetlax.org.mx/assets/pdf/transparencia/infContable/cuentaPub/2.pdf",
  },
  {
    id: 1,
    year: "2016",
    title: "Cuenta Pública 1er Trimestre",
    link: "https://itetlax.org.mx/assets/pdf/transparencia/infContable/cuentaPub2016/1.pdf",
  },
  {
    id: 2,
    year: "2016",
    title: "Cuenta Pública 2do Trimestre",
    link: "https://itetlax.org.mx/assets/pdf/transparencia/infContable/cuentaPub2016/2.pdf",
  },
  {
    id: 3,
    year: "2016",
    title: "Cuenta Pública 3er Trimestre",
    link: "https://itetlax.org.mx/assets/pdf/transparencia/infContable/cuentaPub2016/3.pdf",
  },
  {
    id: 4,
    year: "2016",
    title: "Cuenta Pública 4to Trimestre",
    link: "https://itetlax.org.mx/assets/pdf/transparencia/infContable/cuentaPub2016/4.pdf",
  },
  {
    id: 5,
    year: "2017",
    title: "Cuenta Pública 1er Trimestre",
    link: "https://itetlax.org.mx/assets/pdf/transparencia/infContable/cuentaPub2017/1.pdf",
  },
  {
    id: 6,
    year: "2017",
    title: "Cuenta Pública 2do Trimestre",
    link: "https://itetlax.org.mx/assets/pdf/transparencia/infContable/cuentaPub2017/2.pdf",
  },
  {
    id: 7,
    year: "2017",
    title: "Cuenta Pública 3er Trimestre",
    link: "https://itetlax.org.mx/assets/pdf/transparencia/infContable/cuentaPub2017/3.pdf",
  },
  {
    id: 8,
    year: "2017",
    title: "Cuenta Pública 4to Trimestre",
    link: "https://itetlax.org.mx/assets/pdf/transparencia/infContable/cuentaPub2017/4.pdf",
  },
  {
    id: 9,
    year: "2018",
    title: "Cuenta Pública 1er Trimestre",
    link: "https://itetlax.org.mx/assets/pdf/transparencia/infContable/cuentaPub2018/1.pdf",
  },
  {
    id: 10,
    year: "2018",
    title: "Cuenta Pública 2do Trimestre",
    link: "https://itetlax.org.mx/assets/pdf/transparencia/infContable/cuentaPub2018/2.pdf",
  },
  {
    id: 11,
    year: "2018",
    title: "Cuenta Pública 3er Trimestre",
    link: "https://itetlax.org.mx/assets/pdf/transparencia/infContable/cuentaPub2018/3.pdf",
  },
  {
    id: 12,
    year: "2018",
    title: "Cuenta Pública 4to Trimestre",
    link: "https://itetlax.org.mx/assets/pdf/transparencia/infContable/cuentaPub2018/4.pdf",
  },
  {
    id: 13,
    year: "2019",
    title: "Cuenta Pública 1er Trimestre",
    link: "https://itetlax.org.mx/assets/pdf/transparencia/infContable/cuentaPub2019/1.pdf",
  },
  {
    id: 14,
    year: "2019",
    title: "Cuenta Pública 2do Trimestre",
    link: "https://itetlax.org.mx/assets/pdf/transparencia/infContable/cuentaPub2019/2.pdf",
  },
  {
    id: 15,
    year: "2019",
    title: "Cuenta Pública 3er Trimestre",
    link: "https://itetlax.org.mx/assets/pdf/transparencia/infContable/cuentaPub2019/3.pdf",
  },
  {
    id: 16,
    year: "2019",
    title: "Cuenta Pública 4to Trimestre",
    link: "https://itetlax.org.mx/assets/pdf/transparencia/infContable/cuentaPub2019/4.pdf",
  },
  {
    id: 17,
    year: "2020",
    title: "Cuenta Pública 1er Trimestre",
    link: "https://itetlax.org.mx/assets/pdf/transparencia/infContable/cuentaPub2020/1.pdf",
  },
  {
    id: 18,
    year: "2020",
    title: "Cuenta Pública 2do Trimestre",
    link: "https://itetlax.org.mx/assets/pdf/transparencia/infContable/cuentaPub2020/2.pdf",
  },
  {
    id: 19,
    year: "2020",
    title: "Cuenta Pública 3er Trimestre",
    link: "https://itetlax.org.mx/assets/pdf/transparencia/infContable/cuentaPub2020/3.pdf",
  },
  {
    id: 20,
    year: "2020",
    title: "Cuenta Pública 4to Trimestre",
    link: "https://itetlax.org.mx/assets/pdf/transparencia/infContable/cuentaPub2020/4.pdf",
  },
  {
    id: 21,
    year: "2021",
    title: "Cuenta Pública 1er Trimestre",
    link: "https://itetlax.org.mx/assets/pdf/transparencia/infContable/cuentaPub2021/1.pdf",
  },
  {
    id: 22,
    year: "2021",
    title: "Cuenta Pública 2do Trimestre",
    link: "https://itetlax.org.mx/assets/pdf/transparencia/infContable/cuentaPub2021/2.pdf",
  },
  {
    id: 23,
    year: "2021",
    title: "Cuenta Pública 3er Trimestre",
    link: "https://itetlax.org.mx/assets/pdf/transparencia/infContable/cuentaPub2021/3.pdf",
  },
  {
    id: 24,
    year: "2021",
    title: "Cuenta Pública 4to Trimestre",
    link: "https://itetlax.org.mx/assets/pdf/transparencia/infContable/cuentaPub2021/4.pdf",
  },
  {
    id: 25,
    year: "2022",
    title: "Cuenta Pública 1er Trimestre",
    link: "https://itetlax.org.mx/assets/pdf/transparencia/infContable/cuentaPub2022/1.pdf",
  },
  {
    id: 26,
    year: "2022",
    title: "Cuenta Pública 2do Trimestre",
    link: "https://itetlax.org.mx/assets/pdf/transparencia/infContable/cuentaPub2022/2.pdf",
  },
  {
    id: 27,
    year: "2022",
    title: "Cuenta Pública 3er Trimestre",
    link: "https://itetlax.org.mx/assets/pdf/transparencia/infContable/cuentaPub2022/3.pdf",
  },
  {
    id: 28,
    year: "2022",
    title: "Cuenta Pública 4to Trimestre",
    link: "https://itetlax.org.mx/assets/pdf/transparencia/infContable/cuentaPub2022/4.pdf",
  },
  {
    id: 29,
    year: "2023",
    title: "Cuenta Pública 1er Trimestre",
    link: "https://itetlax.org.mx/assets/pdf/transparencia/infContable/cuentaPub2023/1.pdf",
  },
  {
    id: 30,
    year: "2023",
    title: "Cuenta Pública 2do Trimestre",
    link: "https://itetlax.org.mx/assets/pdf/transparencia/infContable/cuentaPub2023/2.pdf",
  },
  {
    id: 30,
    year: "2023",
    title: "Cuenta Pública 3er Trimestre",
    link: "https://itetlax.org.mx/assets/pdf/transparencia/infContable/cuentaPub2023/3.pdf",
  },
  {
    id: 31,
    year: "2023",
    title: "Cuenta Pública 4to Trimestre",
    link: "https://itetlax.org.mx/assets/pdf/transparencia/infContable/cuentaPub2023/4.pdf",
  },
  {
    id: 32,
    year: "2024",
    title: "Cuenta Pública 1er Trimestre",
    link: "https://itetlax.org.mx/assets/pdf/transparencia/infContable/cuentaPub2024/1.pdf",
  },
  {
    id: 33,
    year: "2024",
    title: "Cuenta Pública 2do Trimestre",
    link: "https://itetlax.org.mx/assets/pdf/transparencia/infContable/cuentaPub2024/2.pdf",
  },
];
