export const dataArt672024 = [
  {
    letra: "A1",
    descriptivo:
      "Los listados de partidos políticos, asociaciones y agrupaciones políticas o de ciudadanos registrados ante la Autoridad Electoral.",
    cumplimiento: "Aplica",
    fundamentoLegal:
      "Articulo. 67 inciso A de la Ley de Transparencia y Acceso a la Información Pública del Estado de Tlaxcala",
    excel1: "LTAIPT_A67F01IA1_1.xlsx",
    excel2: "LTAIPT_A67F01IA1_2.xlsx",
    managerId: null,
  },
  {
    id: 1,
    letra: "B",
    descriptivo: "",
    cumplimiento: "Aplica",
    fundamentoLegal:
      "Articulo. 67 inciso B de la Ley de Transparencia y Acceso a la Información Pública del Estado de Tlaxcala",
    managerId: null,
  },
  {
    letra: "B1",
    descriptivo:
      "Los informes que presenten los partidos políticos, asociaciones y agrupaciones políticas o de ciudadanos.",
    cumplimiento: "Aplica",
    fundamentoLegal:
      "En relación a los informes de ingresos y egresos de los partidos políticos, asociaciones y agrupaciones políticas o de ciudadanos, son presentados ante el Instituto Nacional Electoral; en términos de los artículos 41 apartado V, apartado B, inciso a), numeral 6, de la Ley Fundamental, y 32 de la Ley General de Instituciones y Procedimientos Electorales, por ende el Instituto Tlaxcalteca de Elecciones no es la autoridad competente para recibir dicha documentación",
    managerId: 1,
  },
  {
    letra: "B2",
    descriptivo:
      "Los informes que presenten los partidos políticos, asociaciones y agrupaciones políticas o de ciudadanos.",
    cumplimiento: "Aplica",
    fundamentoLegal:
      "En relación a los informes de ingresos y egresos de los partidos políticos, asociaciones y agrupaciones políticas o de ciudadanos, son presentados ante el Instituto Nacional Electoral; en términos de los artículos 41 apartado V, apartado B, inciso a), numeral 6, de la Ley Fundamental, y 32 de la Ley General de Instituciones y Procedimientos Electorales, por ende el Instituto Tlaxcalteca de Elecciones no es la autoridad competente para recibir dicha documentación.",
    managerId: 1,
  },
  {
    id: 2,
    letra: "C",
    descriptivo: "",
    cumplimiento: "Aplica",
    fundamentoLegal:
      "Articulo. 67 inciso C de la Ley de Transparencia y Acceso a la Información Pública del Estado de Tlaxcala",
    managerId: null,
  },
  {
    letra: "C1",
    descriptivo:
      "Normatividad en materia de geografía y cartografía electoral.",
    cumplimiento: "Aplica",
    fundamentoLegal:
      "Articulo. 67 inciso C1 de la Ley de Transparencia y Acceso a la Información Pública del Estado de Tlaxcala",
    managerId: 2,
  },
  {
    letra: "C2",
    descriptivo: "La geografía y cartografía electoral.",
    cumplimiento: "Aplica",
    fundamentoLegal:
      "Articulo. 67 inciso C2 de la Ley de Transparencia y Acceso a la Información Pública del Estado de Tlaxcala",
    managerId: 2,
  },
  {
    letra: "C3",
    descriptivo: "Estudios en materia de geografía y cartografía.",
    cumplimiento: "Aplica",
    fundamentoLegal:
      "Articulo. 67 inciso C3 de la Ley de Transparencia y Acceso a la Información Pública del Estado de Tlaxcala",
    managerId: 2,
  },
  {
    id: 3,
    letra: "D",
    descriptivo: "",
    cumplimiento: "Aplica",
    fundamentoLegal:
      "Articulo. 67 inciso D de la Ley de Transparencia y Acceso a la Información Pública del Estado de Tlaxcala",
    managerId: null,
  },
  {
    letra: "D1",
    descriptivo: "El registro de candidatos a cargos de elección popular.",
    cumplimiento: "Aplica",
    fundamentoLegal:
      "Articulo. 67 inciso D1 de la Ley de Transparencia y Acceso a la Información Pública del Estado de Tlaxcala",
    managerId: 3,
  },
  {
    letra: "D2",
    descriptivo:
      "Sistema de consulta de candidatos a cargos de elección popular.",
    cumplimiento: "Aplica",
    fundamentoLegal:
      "Articulo. 67 inciso D2 de la Ley de Transparencia y Acceso a la Información Pública del Estado de Tlaxcala",
    managerId: 3,
  },
  {
    letra: "D3",
    descriptivo: "Candidatos electos a cargos de elección popular.",
    cumplimiento: "Aplica",
    fundamentoLegal:
      "Articulo. 67 inciso D3 de la Ley de Transparencia y Acceso a la Información Pública del Estado de Tlaxcala",
    managerId: 3,
  },
  {
    letra: "D4",
    descriptivo:
      "Sistema de consulta de candidatos electos a cargos de elección popular.",
    cumplimiento: "Aplica",
    fundamentoLegal:
      "Articulo. 67 inciso D4 de la Ley de Transparencia y Acceso a la Información Pública del Estado de Tlaxcala",
    managerId: 3,
  },
  {
    id: 4,
    letra: "E",
    descriptivo: "",
    cumplimiento: "Aplica",
    fundamentoLegal:
      "Articulo. 67 inciso E de la Ley de Transparencia y Acceso a la Información Pública del Estado de Tlaxcala",
    managerId: null,
  },
  {
    letra: "E1",
    descriptivo:
      "El catálogo de estaciones de radio y canales de televisión, pautas de transmisión, versiones de spots del Instituto Tlaxcalteca de Elecciones y de los partidos políticos.",
    cumplimiento: "Aplica",
    fundamentoLegal:
      "Articulo. 67 inciso E1 de la Ley de Transparencia y Acceso a la Información Pública del Estado de Tlaxcala",
    excel1: "LTAIPT_A67F01IE1_1.xlsx",
    excel2: "LTAIPT_A67F01IE1_2.xlsx",
    managerId: 4,
  },
  {
    letra: "E2",
    descriptivo:
      "El catálogo de estaciones de radio y canales de televisión, pautas de transmisión, versiones de spots del Instituto Tlaxcalteca de Elecciones y de los partidos políticos.",
    cumplimiento: "Aplica",
    fundamentoLegal:
      "Articulo. 67 inciso E2 de la Ley de Transparencia y Acceso a la Información Pública del Estado de Tlaxcala",
    excel1: "LTAIPT_A67F01IE2_1.xlsx",
    excel2: "LTAIPT_A67F01IE2_2.xlsx",
    managerId: 4,
  },
  {
    letra: "E3",
    descriptivo:
      "El catálogo de estaciones de radio y canales de televisión, pautas de transmisión, versiones de spots del Instituto Tlaxcalteca de Elecciones y de los partidos políticos.",
    cumplimiento: "Aplica",
    fundamentoLegal:
      "Articulo. 67 inciso E3 de la Ley de Transparencia y Acceso a la Información Pública del Estado de Tlaxcala",
    excel1: "LTAIPT_A67F01IE3_1.xlsx",
    excel2: "LTAIPT_A67F01IE3_2.xlsx",
    managerId: 4,
  },
  {
    id: 5,
    letra: "F",
    descriptivo: "",
    cumplimiento: "Aplica",
    fundamentoLegal:
      "Articulo. 67 inciso F de la Ley de Transparencia y Acceso a la Información Pública del Estado de Tlaxcala",
    managerId: null,
  },
  {
    letra: "F1",
    descriptivo:
      "Los montos de financiamiento público por actividades ordinarias, de campaña y específicas otorgadas a los partidos políticos, asociaciones y agrupaciones políticas o de ciudadanos y demás asociaciones políticas, así como los montos autorizados de financiamiento privado y los topes de los gastos de campañas.",
    cumplimiento: "Aplica",
    fundamentoLegal:
      "Articulo. 67 inciso F1 de la Ley de Transparencia y Acceso a la Información Pública del Estado de Tlaxcala",
    excel1: "LTAIPT_A67F01IJ.xlsx",
    managerId: 5,
  },
  {
    letra: "F2",
    descriptivo:
      "Los montos de financiamiento público por actividades ordinarias, de campaña y específicas otorgadas a los partidos políticos, asociaciones y agrupaciones políticas o de ciudadanos y demás asociaciones políticas, así como los montos autorizados de financiamiento privado y los topes de los gastos de campañas.",
    cumplimiento: "Aplica",
    fundamentoLegal:
      "Articulo. 67 inciso E2 de la Ley de Transparencia y Acceso a la Información Pública del Estado de Tlaxcala",
    managerId: 5,
  },
  {
    letra: "F3",
    descriptivo:
      "Los montos de financiamiento público por actividades ordinarias, de campaña y específicas otorgadas a los partidos políticos, asociaciones y agrupaciones políticas o de ciudadanos y demás asociaciones políticas, así como los montos autorizados de financiamiento privado y los topes de los gastos de campañas.",
    cumplimiento: "Aplica",
    fundamentoLegal:
      "Articulo. 67 inciso F3 de la Ley de Transparencia y Acceso a la Información Pública del Estado de Tlaxcala",
    managerId: 5,
  },
  {
    id: 6,
    letra: "G",
    descriptivo:
      "La metodología e informes sobre la publicación de encuestas por muestreo, encuestas de salida y conteos rápidos.",
    cumplimiento: "Aplica",
    fundamentoLegal:
      "Articulo. 67 inciso G de la Ley de Transparencia y Acceso a la Información Pública del Estado de Tlaxcala",
    managerId: null,
  },
  {
    letra: "G1",
    descriptivo: "Normatividad del INE en materia de encuestas electorales",
    cumplimiento: "Aplica",
    fundamentoLegal:
      "Articulo. 67 inciso G1 de la Ley de Transparencia y Acceso a la Información Pública del Estado de Tlaxcala",
    excel1: "LTAIPT_A67F01IG1_1.xlsx",
    excel2: "LTAIPT_A67F01IG1_2.xlsx",
    managerId: 6,
  },
  {
    letra: "G2",
    descriptivo:
      "Monitoreo de encuestas electorales, sondeos de opinión y contos rápidos no institucionales detectados por la Coordinación Nacional  de Comunicación",
    cumplimiento: "Aplica",
    fundamentoLegal:
      "Articulo. 67 inciso D2 de la Ley de Transparencia y Acceso a la Información Pública del Estado de Tlaxcala",
    excel1: "LTAIPT_A67F01IG2_1.xlsx",
    excel2: "LTAIPT_A67F01IG2_2.xlsx",
    excel3: "LTAIPT_A67F01IG2_3.xlsx",
    managerId: 6,
  },
  {
    letra: "G3",
    descriptivo: "Estudios recibidos",
    cumplimiento: "Aplica",
    fundamentoLegal:
      "Articulo. 67 inciso G3 de la Ley de Transparencia y Acceso a la Información Pública del Estado de Tlaxcala",
    excel1: "LTAIPT_A67F01IG3_1.xlsx",
    excel2: "LTAIPT_A67F01IG3_2.xlsx",
    managerId: 6,
  },
  {
    letra: "G4",
    descriptivo:
      "Informes presentados por la Secretaría Ejecutiva en el Consejo General",
    cumplimiento: "Aplica",
    fundamentoLegal:
      "Articulo. 67 inciso G4 de la Ley de Transparencia y Acceso a la Información Pública del Estado de Tlaxcala",
    excel1: "LTAIPT_A67F01IG4_1.xlsx",
    excel2: "LTAIPT_A67F01IG4_2.xlsx",
    managerId: 6,
  },
  {
    letra: "G5",
    descriptivo:
      "Personas físicas o morales que pretenden efectuar encuestas de salida y/o conteos rápidos no institucionales",
    cumplimiento: "Aplica",
    fundamentoLegal:
      "Articulo. 67 inciso G5 de la Ley de Transparencia y Acceso a la Información Pública del Estado de Tlaxcala",
    excel1: "LTAIPT_A67F01IG5_1.xlsx",
    excel2: "LTAIPT_A67F01IG5_2.xlsx",
    managerId: 6,
  },
  {
    letra: "G6",
    descriptivo:
      "Informes que, en su caso, presentó el sujeto obligado ante sus máximos órganos de dirección relacionados con el tema.",
    cumplimiento: "Aplica",
    fundamentoLegal:
      "Articulo. 67 inciso G6 de la Ley de Transparencia y Acceso a la Información Pública del Estado de Tlaxcala.",
    excel1: "LTAIPT_A67F01IG6_1.xlsx",
    excel2: "LTAIPT_A67F01IG6_2.xlsx",
    managerId: 6,
  },
  {
    id: 7,
    letra: "H",
    descriptivo:
      "La metodología e informe del Programa de Resultados Preliminares Electorales.",
    cumplimiento: "Aplica",
    fundamentoLegal:
      "Articulo. 67 inciso H de la Ley de Transparencia y Acceso a la Información Pública del Estado de Tlaxcala",
    managerId: null,
  },
  {
    letra: "H1",
    descriptivo:
      "Metodología del programa de resultados electorales preliminares.",
    cumplimiento: "Aplica",
    fundamentoLegal:
      "Articulo. 67 inciso H1 de la Ley de Transparencia y Acceso a la Información Pública del Estado de Tlaxcala",
    excel1: "LTAIPT_A67F01IH1.xlsx",
    managerId: 7,
  },
  {
    letra: "H2",
    descriptivo: "Programa de Resultados Electorales Preliminares (PREP).",
    cumplimiento: "Aplica",
    fundamentoLegal:
      "Articulo. 67 inciso H2 de la Ley de Transparencia y Acceso a la Información Pública del Estado de Tlaxcala",
    excel1: "LTAIPT_A67F01IH2.xlsx",
    managerId: 7,
  },
  {
    letra: "I",
    descriptivo:
      "Los cómputos totales de las elecciones y procesos de participación ciudadana.",
    cumplimiento: "Aplica",
    fundamentoLegal:
      "Articulo. 67 inciso I de la Ley de Transparencia y Acceso a la Información Pública del Estado de Tlaxcala",
    managerId: null,
  },
  {
    letra: "J",
    descriptivo: "Los resultados y declaraciones de validez de las elecciones.",
    cumplimiento: "Aplica",
    fundamentoLegal:
      "Articulo. 67 inciso J de la Ley de Transparencia y Acceso a la Información Pública del Estado de Tlaxcala",
    managerId: null,
  },
  {
    letra: "K",
    descriptivo:
      "Las franquicias postales y telegráficas asignadas al partido político para el cumplimiento de sus funciones.",
    cumplimiento: "Aplica",
    fundamentoLegal:
      "Los partidos políticos nacionales disfrutarán de las franquicias postales y telegráficas, dentro del territorio nacional, que sean necesarias para el desarrollo de sus actividades, conforme a las reglas establecidas en los artículos 188 de la Ley General de Instituciones y Procedimientos Electorales y 70 de la Ley General de Partidos Políticos correspondiendo al Instituto Nacional Electoral aplicarlas. Para consulta:http://www.ine.mx/archivos3/portal/historico/contenido/Franquicias_Postales/",
    managerId: null,
  },
  {
    letra: "L",
    descriptivo:
      "Los dictámenes, informes y resoluciones sobre pérdida de registro y liquidación del patrimonio de los partidos políticos nacionales y estatales",
    cumplimiento: "Aplica",
    fundamentoLegal:
      "En el Instituto Tlaxcalteca de Elecciones durante el transcurso del año dos mil dieciséis, ningún partido político nacional se ha colocado en el supuesto de pérdida de registro señalado por el artículo 94 de la Ley General de Partidos Políticos, por ende tampoco se ha actualizado el supuesto de pérdida de acreditación en el ámbito local, previsto en el artículo 45 de la Ley de Partidos Políticos para el Estado de Tlaxcala. Asimismo, en el caso de los partidos políticos con registro local, ninguno se ha colocado en alguno de los supuestos que prevé el artículo 44 de la Ley de Partidos Políticos para el Estado de Tlaxcala, relativos a causales para perder su registro. Se entiende que en ningún caso se ha dado el supuesto de liquidación de patrimonio de partidos políticos nacionales o locales.",
    managerId: null,
  },
  {
    id: 8,
    letra: "M",
    descriptivo: "El monitoreo de medios.",
    cumplimiento: "Aplica",
    fundamentoLegal:
      "Articulo. 67 inciso M de la Ley de Transparencia y Acceso a la Información Pública del Estado de Tlaxcala",
    managerId: null,
  },
  {
    letra: "M1",
    descriptivo:
      "Monitoreo de los espacios de radio y telivisión que difunden noticias.",
    cumplimiento: "Aplica",
    fundamentoLegal:
      "Articulo. 67 inciso M1 de la Ley de Transparencia y Acceso a la Información Pública del Estado de Tlaxcala",
    excel1: "LTAIPT_A67F01IM1_1.xlsx",
    excel2: "LTAIPT_A67F01IM1_2.xlsx",
    managerId: 8,
  },
  {
    letra: "M2",
    descriptivo:
      "Informes, convenios, contratos y datos de la institución pública o empresa encargada del monitoreo de los espacios de radio y televisión que difunden noticias.",
    cumplimiento: "Aplica",
    fundamentoLegal:
      "Articulo. 67 inciso M2 de la Ley de Transparencia y Acceso a la Información Pública del Estado de Tlaxcala",
    excel1: "LTAIPT_A67F01IM2_1.xlsx",
    excel2: "LTAIPT_A67F01IM2_2.xlsx",
    managerId: 8,
  },
  {
    letra: "M3",
    descriptivo:
      "Resultados, metodología y catálogo para el monitoreo de transmiciones sobre las precampañas, y campañas electorales de los espacios en radio y televisión que difunden noticias.",
    cumplimiento: "Aplica",
    fundamentoLegal:
      "Articulo. 67 inciso M3 de la Ley de Transparencia y Acceso a la Información Pública del Estado de Tlaxcala",
    excel1: "LTAIPT_A67F01IM3_1.xlsx",
    excel2: "LTAIPT_A67F01IM3_2.xlsx",
    managerId: 8,
  },
  {
    letra: "M4",
    descriptivo:
      "Monitoreo de las pautas de transmisión en radio y telivisión.",
    cumplimiento: "Aplica",
    fundamentoLegal:
      "Articulo. 67 inciso M4 de la Ley de Transparencia y Acceso a la Información Pública del Estado de Tlaxcala",
    excel1: "LTAIPT_A67F01IM4_1.xlsx",
    excel2: "LTAIPT_A67F01IM4_2.xlsx",
    managerId: 8,
  },
];
