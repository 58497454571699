export const dataBoletines = {
  2015: [
    {
      id: 1,
      monthBoletin: "OCT",
      nameBoletin: "Comunicado 001 APRUEBA ITE REGLAMENTO DE SESIONES",
      linkBoletin: "1",
    },
    {
      id: 2,
      monthBoletin: "NOV",
      nameBoletin:
        "Comunicado 005 MEDIANTE ACUERDO AVALA ITE ADECUACIÓN PRESUPUESTAL",
      linkBoletin: "2",
    },
    {
      id: 3,
      monthBoletin: "DIC",
      nameBoletin:
        "Comunicado 012 CONCLUYE ITE APROBACIÓN DE REGLAMENTOS INTERNOS Y CAPACITA A PERSONAL EN DERECHO ELECTORAL",
      linkBoletin: "3",
    },
    {
      id: 4,
      monthBoletin: "DIC",
      nameBoletin: "Comunicado 013 INICIA PROCESO ELECTORAL LOCAL 2015-2016",
      linkBoletin: "4",
    },
    {
      id: 5,
      monthBoletin: "DIC",
      nameBoletin: "Comunicado 014 DEFINE ITE TOPES DE PRECAMPAÑA",
      linkBoletin: "5",
    },
    {
      id: 6,
      monthBoletin: "DIC",
      nameBoletin:
        "Comunicado 015 NECESARIO PRESUPUESTO DE 254 MDP PARA ELECCIONES 2016",
      linkBoletin: "6",
    },
    {
      id: 7,
      monthBoletin: "DIC",
      nameBoletin:
        "Comunicado 016 LISTOS ACUERDOS DEL ITE QUE REGIRÁN CANDIDATURAS INDEPENDIENTES",
      linkBoletin: "7",
    },
    {
      id: 8,
      monthBoletin: "DIC",
      nameBoletin:
        "Comunicado 017 FIRMA ITE ACUERDO DE COLABORACIÓN CON EL INE Y APRUEBA MULTAS PARA PAN Y PT",
      linkBoletin: "8",
    },
    {
      id: 9,
      monthBoletin: "DIC",
      nameBoletin:
        "Comunicado 018 HABRÁ ENTRE 5 Y 7 REGIDORES EN AYUNTAMIENTOS ITE",
      linkBoletin: "9",
    },
    {
      id: 10,
      monthBoletin: "DIC",
      nameBoletin:
        "Comunicado 019 AJUSTA ITE CONVOCATORIA DE CANDIDATURAS INDEPENDIENTES",
      linkBoletin: "10",
    },
    {
      id: 11,
      monthBoletin: "DIC",
      nameBoletin:
        "Comunicado 020 APRUEBA ITE LINEAMIENTOS PARA NOTICIARIOS Y CRITERIOS PARA EL USO DE MATERIAL RECICLABLE PARA PROPAGANDA",
      linkBoletin: "11",
    },
  ],

  2016: [
    {
      id: 1,
      monthBoletin: "ENE",
      nameBoletin:
        "BOLETÍN-001-ELIGEN-COMUNIDADES-DE-NATIVITAS-Y-SANTA-APOLONIA-TEACALCO-A-PRESIDENTES-POR-SISTEMA-DE-USOS-Y-COSTUMBRES",
      linkBoletin: "1",
    },
    {
      id: 2,
      monthBoletin: "ENE",
      nameBoletin:
        "BOLETÍN-002-FIRMARÁN-CONVENIO-ITE-Y-TRIBUNAL-ELECTORAL-DE-JALISCO",
      linkBoletin: "2",
    },
    {
      id: 3,
      monthBoletin: "ENE",
      nameBoletin:
        "BOLETÍN-003-ABRIRÁN-ITE-Y-TRIEJAL-DOCTORADO-EN-DERECHO-ELECTORAL",
      linkBoletin: "3",
    },
    {
      id: 4,
      monthBoletin: "ENE",
      nameBoletin:
        "BOLETÍN-004-CELEBRAN-ELECCIONES-POR-USOS-Y-COSTUMBRES-COMUNIDADES-DE-NATIVITAS-TERRENATE-Y-CHIAUTEMPAN",
      linkBoletin: "4",
    },
    {
      id: 5,
      monthBoletin: "ENE",
      nameBoletin:
        "BOLETÍN-005-APRUEBA-ITE-PRESUPUESTO-DE-EGRESOS-EN-ESTRICTO-APEGO-A-DECRETO",
      linkBoletin: "5",
    },
    {
      id: 6,
      monthBoletin: "ENE",
      nameBoletin:
        "BOLETÍN-006-APRUEBA-ITE-INTEGRACIÓN-DEL-COMITÉ-TÉCNICO-DE-ARCHIVOS",
      linkBoletin: "6",
    },
    {
      id: 7,
      monthBoletin: "FEB",
      nameBoletin:
        "BOLETÍN-007-PROCESO-ELECTORAL-2015-2016-DIO-MUESTRA-DE-FORTALECIMIENTO-DEMOCRÁTICO-ITE",
      linkBoletin: "7",
    },
    {
      id: 8,
      monthBoletin: "FEB",
      nameBoletin:
        "BOLETÍN-008-ACATA-ITE-SENTENCIA-DEL-TET-Y-REASIGNA-PRESUPUESTO-2017",
      linkBoletin: "8",
    },
    {
      id: 9,
      monthBoletin: "FEB",
      nameBoletin: "BOLETÍN-009-REUTILIZARÁ-ITE-MATERIALES-ELECTORALES",
      linkBoletin: "9",
    },
    {
      id: 10,
      monthBoletin: "FEB",
      nameBoletin:
        "BOLETÍN-010-ABRE-ITE-BODEGAS-PARA-EXTRACCIÓN-DE-DOCUMENTACIÓN-Y-MATERIAL-ELECTORAL",
      linkBoletin: "10",
    },
    {
      id: 11,
      monthBoletin: "MRZ",
      nameBoletin:
        "BOLETÍN-11-AL-50-PREPARACIÓN-DE-DOCUMENTACIÓN-ELECTORAL-QUE-SERÁ-DESTRUÍDA",
      linkBoletin: "11",
    },
    {
      id: 12,
      monthBoletin: "MRZ",
      nameBoletin:
        "BOLETÍN-12-EL-ITE-CUENTA-CON-RECURSOS-PARA-ELECCIONES-EXTRAORDINARIAS",
      linkBoletin: "12",
    },
    {
      id: 13,
      monthBoletin: "MRZ",
      nameBoletin:
        "BOLETÍN-13-APRUEBA-ITE-CALENDARIO-DEL-PROCESO-ELECTORAL-EXTRAORDINARIO",
      linkBoletin: "13",
    },
    {
      id: 14,
      monthBoletin: "MRZ",
      nameBoletin:
        "BOLETÍN-14-DECLARA-ITE-INICIO-DEL-PROCESO-ELECTORAL-EXTRAORDINARIO-2017",
      linkBoletin: "14",
    },
    {
      id: 15,
      monthBoletin: "ABRIL",
      nameBoletin:
        "Comunicado-ITE-041-REALIZÓ-ITE-CONVENCIÓN-ESTATAL-DEL-8°.-PARLAMENTO-INFANTIL-SE-ELIGIERON-A-19-LEGISLADORES",
      linkBoletin: "15",
    },
    {
      id: 16,
      monthBoletin: "JUN",
      nameBoletin:
        "BOLETÍN-ITE-058-EXHORTA-ITE-A-TLAXCALTECAS-A-REFLEXIONAR-SU-VOTO",
      linkBoletin: "16",
    },
    {
      id: 17,
      monthBoletin: "JUN",
      nameBoletin: "BOLETÍN-ITE-059-CUBRE-ITE-SALARIOS-APEGADO-A-LEY",
      linkBoletin: "17",
    },
    {
      id: 18,
      monthBoletin: "JUN",
      nameBoletin:
        "BOLETÍN-ITE-060-CELEBRA-ITE-CONFERENCIA-Y-FORO-PARA-PROMOVER-EL-VOTO",
      linkBoletin: "18",
    },
    {
      id: 19,
      monthBoletin: "JUN",
      nameBoletin:
        "BOLETÍN-ITE-061-SESIONAN-CONSEJEROS-DISTRITALES-Y-MUNICIPALES-PARA-CÓMPUTOS-DE-ELECCIÓN",
      linkBoletin: "19",
    },
    {
      id: 20,
      monthBoletin: "JUN",
      nameBoletin:
        "BOLETÍN-ITE-062-INICIA-ITE-CÓMPUTO-FINAL-DE-LA-JORNADA-ELECTORAL",
      linkBoletin: "20",
    },
    {
      id: 21,
      monthBoletin: "JUN",
      nameBoletin:
        "BOLETÍN-ITE-063-EXHORTA-ITE-A-NO-USAR-CELULARES-PARA-CAPTAR-IMÁGENES-AL-INTERIOR-DE-CASILLAS",
      linkBoletin: "21",
    },
    {
      id: 22,
      monthBoletin: "JUN",
      nameBoletin:
        "BOLETÍN-ITE-064-LAMENTA-CONSEJO-GENERAL-DEL-ITE-RETENCIÓN-ILEGAL-DE-COLABORADORES",
      linkBoletin: "22",
    },
    {
      id: 23,
      monthBoletin: "JUN",
      nameBoletin:
        "BOLETÍN-ITE-065-CONCLUYEN-CÓMPUTOS-CONSEJOS-DISTRITALES-Y-MUNICIPALES-ENTREGAN-CONSTANCIAS-DE-JUNRÍA",
      linkBoletin: "23",
    },
    {
      id: 24,
      monthBoletin: "JUN",
      nameBoletin:
        "BOLETÍN-ITE-066-CONCLUYE-ITE-CÓMPUTO-PARA-GOBERNADOR-Y-ENTREGA-CONSTANCIA-DE-JUNRÍA",
      linkBoletin: "24",
    },
    {
      id: 25,
      monthBoletin: "JUN",
      nameBoletin:
        "BOLETÍN-ITE-067-RECONOCE-ITE-COMPROMISO-Y-PROFESIONALISMO-DE-CONSEJEROS-DISTRITALES-Y-MUNICIPALES",
      linkBoletin: "25",
    },
    {
      id: 26,
      monthBoletin: "JUN",
      nameBoletin: "Boletín-ITE-068-CELEBRARÁ-ITE-EJERCICIO-DE-EVALUACICIÓN",
      linkBoletin: "26",
    },
    {
      id: 27,
      monthBoletin: "JUN",
      nameBoletin:
        "BOLETÍN-ITE-070-DISCUTEN-CONSEJEROS-DISTRITALES-Y-MUNICIPALES-SOBRE-FORTALEZAS-Y-DEBILIDADES-DEL-PROCESO-ELECTORAL",
      linkBoletin: "27",
    },
    {
      id: 28,
      monthBoletin: "JUL",
      nameBoletin: "1-de-julio-de-2016-1",
      linkBoletin: "28",
    },
    {
      id: 29,
      monthBoletin: "JUL",
      nameBoletin: "BOLETÍN-085-26-DE-JUL-2016",
      linkBoletin: "29",
    },
    {
      id: 30,
      monthBoletin: "JUL",
      nameBoletin: "BOLETÍN-086-31-DE-JUL-2016",
      linkBoletin: "30",
    },
    {
      id: 31,
      monthBoletin: "JUL",
      nameBoletin: "BOLETÍN-22-DE-JUL-2016",
      linkBoletin: "31",
    },
    {
      id: 32,
      monthBoletin: "AGO",
      nameBoletin: "BOLETÍN-087-03-DE-AGOSTO-2016",
      linkBoletin: "32",
    },
    {
      id: 33,
      monthBoletin: "AGO",
      nameBoletin:
        "BOLETÍN-088-04-DE-AGOSTO-2016-ACATA-ITE-INSTRUCCIÓN-DE-INE-EN-RETENCIÓN-DE-PRERROGATIVAS-POR-MULTAS",
      linkBoletin: "33",
    },
    {
      id: 34,
      monthBoletin: "AGO",
      nameBoletin: "BOLETÍN-089-05-DE-AGOSTO-2016",
      linkBoletin: "34",
    },
    {
      id: 35,
      monthBoletin: "AGO",
      nameBoletin: "BOLETÍN-090-08-DE-AGOSTO-2016",
      linkBoletin: "35",
    },
    {
      id: 36,
      monthBoletin: "AGO",
      nameBoletin: "BOLETÍN-091-09-DE-AGOSTO-2016",
      linkBoletin: "36",
    },
    {
      id: 37,
      monthBoletin: "AGO",
      nameBoletin:
        "BOLETÍN-092-17-DE-AGOSTO-2016-INTERVIENE-ITE-ANTE-INE-PARA-APOYAR-A-PARTIDOS-EN-MATERIA-DE-MULTAS",
      linkBoletin: "37",
    },
    {
      id: 38,
      monthBoletin: "AGO",
      nameBoletin:
        "BOLETÍN-093-18-DE-AGOSTO-2016-CONFIRMA-SALA-REGIONAL-TRABAJO-DE-ITE-EN-VALIDACIÓN-DE-ELECCIONES",
      linkBoletin: "38",
    },
    {
      id: 39,
      monthBoletin: "AGO",
      nameBoletin:
        "BOLETÍN-094-30-DE-AGOSTO-2016-ADECUA-ITE-PRESUPUESTO-DE-EGRESOS-PARA-HACER-FRENTE-A-ELECCIONES-EXTRAORDINARIAS",
      linkBoletin: "39",
    },
    {
      id: 40,
      monthBoletin: "SEP",
      nameBoletin:
        "BOLETÍN-095-05-DE-SEPTIEMBRE-2016-PREPARA-INSTITUTO-TLAXCALTECA-DE-ELECCIONES-PLAN-DE-CULTURA-CÍVICA",
      linkBoletin: "40",
    },
    {
      id: 41,
      monthBoletin: "SEP",
      nameBoletin:
        "BOLETÍN-096-06-DE-SEPTIEMBRE-2016-PARTICIPA-ITE-EN-EVALUACIÓN-DE-PROCESOS-ELECTORALES-LOCALES-CON-EL-INE",
      linkBoletin: "41",
    },
    {
      id: 42,
      monthBoletin: "SEP",
      nameBoletin:
        "BOLETÍN-097-13-DE-SEPTIEMBRE-2016-EFECTÚA-ITE-RECUENTO-DE-13-CASILLAS-DE-ELECCIÓN-EN-TZOMPANTEPEC-",
      linkBoletin: "42",
    },
    {
      id: 43,
      monthBoletin: "SEP",
      nameBoletin:
        "BOLETÍN-098-14-DE-SEPTIEMBRE-2016-DESAHOGAN-ITE-Y-TET-RECUENTO-DE-CASILLA-EN-ELECCIÓN-DE-AYUNTAMIENTO-DE-NANACAMILPA-DE-MARIANO-ARISTA",
      linkBoletin: "43",
    },
    {
      id: 44,
      monthBoletin: "SEP",
      nameBoletin:
        "BOLETÍN-099-17-DE-SEPTIEMBRE-2016-RECUENTA-ITE-VOTOS-DE-86-PAQUETES-DE-ELECCIÓN-DISTRITAL-DE-TEOLOCHOLCO",
      linkBoletin: "44",
    },
    {
      id: 45,
      monthBoletin: "SEP",
      nameBoletin:
        "BOLETÍN-100-18-DE-SEPTIEMBRE-2016-ACUERDAN-ITE-E-INE-ORGANIZAR-EN-CONJUNTO-ELECCIONES-EXTRAORDINARIAS",
      linkBoletin: "45",
    },
    {
      id: 46,
      monthBoletin: "SEP",
      nameBoletin:
        "BOLETÍN-101-25-DE-SEPTIEMBRE-2016-APOYA-ITE-A-INSTITUCIONES-EDUCATIVAS-EN-ELECCIONES-DE-SOCIEDAD-DE-ALUMNOS-1",
      linkBoletin: "46",
    },
    {
      id: 47,
      monthBoletin: "SEP",
      nameBoletin:
        "BOLETÍN-102-29-DE-SEPTIEMBRE-2016-APRUEBA-ITE-MECANISMO-PARA-ADOPTAR-PROTOCOLO-DE-ATENCIÓN-A-VIOLENCIA-POLÍTICA-CONTRA-LAS-MUJERES-1",
      linkBoletin: "47",
    },
    {
      id: 48,
      monthBoletin: "SEP",
      nameBoletin:
        "BOLETÍN-103-30-DE-SEPTIEMBRE-2016-APRUEBA-ITE-ANTEPROYECTO-DE-PRESUPUESTO-2017-RESPONSABLE-Y-RACIONAL-1",
      linkBoletin: "48",
    },
    {
      id: 49,
      monthBoletin: "OCT",
      nameBoletin:
        "BOLETÍN-104-01-DE-OCTUBRE-2016-ACUERDAN-ITE-Y-GOBIERNO-DEL-ESTADO-PAGO-DE-LAUDOS",
      linkBoletin: "49",
    },
    {
      id: 50,
      monthBoletin: "OCT",
      nameBoletin:
        "BOLETÍN-105-04-DE-OCTUBRE-2016-TLAXCALA-PIONERA-EN-MEDIDAS-DE-ATENCIÓN-A-VIOLENCIA-POLÍTICA-CONTRA-MUJERES",
      linkBoletin: "50",
    },
    {
      id: 51,
      monthBoletin: "OCT",
      nameBoletin:
        "BOLETÍN-106-14-DE-OCTUBRE-2016-APRUEBA-ITE-RETENCIÓN-DE-SANCIONES-A-SEIS-PARTIDOS-POR-GASTOS-DE-CAMPAÑA",
      linkBoletin: "51",
    },
    {
      id: 52,
      monthBoletin: "OCT",
      nameBoletin:
        "BOLETÍN-107-26-DE-OCTUBRE-2016-APRUEBA-ITE-MODIFICACIÓN-A-INTEGRACIÓN-DE-AYUNTAMIENTO-DE-QUILEHTLA",
      linkBoletin: "52",
    },
    {
      id: 53,
      monthBoletin: "OCT",
      nameBoletin:
        "BOLETÍN-108-28-DE-OCTUBRE-2016-PROMUEVE-ITE-REFLEXIÓN-EN-TORNO-A-LA-PARTICIPACIÓN-POLÍTICA-FEMENINA-EN-MÉXICO",
      linkBoletin: "53",
    },
    {
      id: 54,
      monthBoletin: "NOV",
      nameBoletin:
        "BOLETÍN-109-07-NOVIEMBRE-DE-2016-RESPALDA-ITE-ELECCIONES-POR-USOS-Y-COSTUMBRES-EN-CINCO-COMUNIDADES",
      linkBoletin: "54",
    },
    {
      id: 55,
      monthBoletin: "NOV",
      nameBoletin:
        "BOLETÍN-110-15-NOVIEMBRE-DE-2016-CELEBRAN-ELECCIONES-POR-USOS-Y-COSTUMBRES-17-COMUNIDADES-DE-TLAXCALA",
      linkBoletin: "55",
    },
    {
      id: 56,
      monthBoletin: "NOV",
      nameBoletin:
        "BOLETÍN-111-28-NOVIEMBRE-DE-2016-DETALLAN-CONSEJEROS-ELECTORALES-PROPUESTA-DE-PRESPUESTO-2017-A-EJECUTIVO-Y-DIPUTADOS",
      linkBoletin: "56",
    },
    {
      id: 57,
      monthBoletin: "NOV",
      nameBoletin:
        "BOLETÍN-112-30-NOVIEMBRE-DE-2016-APRUEBA-ITE-DESTINO-DE-MULTAS-A-ÓRGANO-DE-CIENCIA-Y-TECNOLOGÍA-LOCAL",
      linkBoletin: "57",
    },
  ],

  2017: [
    {
      id: 1,
      monthBoletin: "ENE",
      nameBoletin:
        "BOLETÍN-001-ELIGEN-COMUNIDADES-DE-NATIVITAS-Y-SANTA-APOLONIA-TEACALCO-A-PRESIDENTES-POR-SISTEMA-DE-USOS-Y-COSTUMBRES",
      linkBoletin: "1",
    },
    {
      id: 2,
      monthBoletin: "ENE",
      nameBoletin:
        "BOLETÍN-002-FIRMARÁN-CONVENIO-ITE-Y-TRIBUNAL-ELECTORAL-DE-JALISCO",
      linkBoletin: "2",
    },
    {
      id: 3,
      monthBoletin: "ENE",
      nameBoletin:
        "BOLETÍN-003-ABRIRÁN-ITE-Y-TRIEJAL-DOCTORADO-EN-DERECHO-ELECTORAL",
      linkBoletin: "3",
    },
    {
      id: 4,
      monthBoletin: "ENE",
      nameBoletin:
        "BOLETÍN-004-CELEBRAN-ELECCIONES-POR-USOS-Y-COSTUMBRES-COMUNIDADES-DE-NATIVITAS-TERRENATE-Y-CHIAUTEMPAN",
      linkBoletin: "4",
    },
    {
      id: 5,
      monthBoletin: "ENE",
      nameBoletin:
        "BOLETÍN-005-APRUEBA-ITE-PRESUPUESTO-DE-EGRESOS-EN-ESTRICTO-APEGO-A-DECRETO",
      linkBoletin: "5",
    },
    {
      id: 6,
      monthBoletin: "ENE",
      nameBoletin:
        "BOLETÍN-006-APRUEBA-ITE-INTEGRACIÓN-DEL-COMITÉ-TÉCNICO-DE-ARCHIVOS",
      linkBoletin: "6",
    },
    {
      id: 7,
      monthBoletin: "FEB",
      nameBoletin:
        "BOLETÍN-007-PROCESO-ELECTORAL-2015-2016-DIO-MUESTRA-DE-FORTALECIMIENTO-DEMOCRÁTICO-ITE",
      linkBoletin: "7",
    },
    {
      id: 8,
      monthBoletin: "FEB",
      nameBoletin:
        "BOLETÍN-008-ACATA-ITE-SENTENCIA-DEL-TET-Y-REASIGNA-PRESUPUESTO-2017",
      linkBoletin: "8",
    },
    {
      id: 9,
      monthBoletin: "FEB",
      nameBoletin: "BOLETÍN-009-REUTILIZARÁ-ITE-MATERIALES-ELECTORALES",
      linkBoletin: "9",
    },
    {
      id: 10,
      monthBoletin: "FEB",
      nameBoletin:
        "BOLETÍN-010-ABRE-ITE-BODEGAS-PARA-EXTRACCIÓN-DE-DOCUMENTACIÓN-Y-MATERIAL-ELECTORAL",
      linkBoletin: "10",
    },
    {
      id: 11,
      monthBoletin: "MAR",
      nameBoletin:
        "BOLETÍN-11-AL-50-PREPARACIÓN-DE-DOCUMENTACIÓN-ELECTORAL-QUE-SERÁ-DESTRUÍDA",
      linkBoletin: "11",
    },
    {
      id: 12,
      monthBoletin: "MAR",
      nameBoletin:
        "BOLETÍN-12-EL-ITE-CUENTA-CON-RECURSOS-PARA-ELECCIONES-EXTRAORDINARIAS",
      linkBoletin: "12",
    },
    {
      id: 13,
      monthBoletin: "MAR",
      nameBoletin:
        "BOLETÍN-13-APRUEBA-ITE-CALENDARIO-DEL-PROCESO-ELECTORAL-EXTRAORDINARIO",
      linkBoletin: "13",
    },
    {
      id: 14,
      monthBoletin: "MAR",
      nameBoletin:
        "BOLETÍN-14-DECLARA-ITE-INICIO-DEL-PROCESO-ELECTORAL-EXTRAORDINARIO-2017",
      linkBoletin: "14",
    },
    {
      id: 15,
      monthBoletin: "MAR",
      nameBoletin:
        "BOLETÍN-15-PRESENTA-ITE-OBRA-DE-TEATRO-SOBRE-ACOSO-Y-VIOLENCIA-LABORAL-CONTRA-LA-MUJER",
      linkBoletin: "15",
    },
    {
      id: 16,
      monthBoletin: "MAR",
      nameBoletin:
        "BOLETÍN-16-DETERMINA-ITE-NO-ADMITIR-A-TRÁMITE-ESCRITOS-DE-NOTIFICACIÓN-PARA-FORMAR-NUEVOS-PARTIDOS-POLÍTICOS",
      linkBoletin: "16",
    },
    {
      id: 17,
      monthBoletin: "MAR",
      nameBoletin:
        "BOLETÍN-17-APRUEBA-ITE-CRITERIOS-DE-PARIDAD-DE-GÉNERO-PARA-ELECCIÓN-EXTRAORDINARIA",
      linkBoletin: "17",
    },
    {
      id: 18,
      monthBoletin: "ABR",
      nameBoletin:
        "BOLETÍN-18-INSUFICIENTES-CONDICIONES-PARA-QUE-MUJERES-ACCEDAN-A-CARGOS-DE-ELECCIÓN-PIEDRAS",
      linkBoletin: "18",
    },
    {
      id: 19,
      monthBoletin: "ABR",
      nameBoletin:
        "BOLETÍN-19-EXHORTA-ITE-A-MIEMBROS-DE-PARLAMENTO-INFANTIL-A-PROMOVER-PARTICIPACIÓN-Y-DIÁLOGO-EN-ESCUELAS-Y-HOGARES",
      linkBoletin: "19",
    },
    {
      id: 20,
      monthBoletin: "ABR",
      nameBoletin:
        "boletín-20-GARANTIZADO-PROCESO-ELECTORAL-EXTRAORDINARIO-ITE",
      linkBoletin: "20",
    },
    {
      id: 21,
      monthBoletin: "ABR",
      nameBoletin:
        "boletín-21-APRUEBA-ITE-DISEÑO-Y-MODELOS-DE-DOCUMENTACIÓN-Y-MATERIAL-ELECTORAL",
      linkBoletin: "21",
    },
    {
      id: 22,
      monthBoletin: "ABR",
      nameBoletin:
        "boletín-22-DESCARTAN-PARTIDOS-IR-EN-CANDIDATURA-COMÚN-O-COALICIÓN-A-PROCESO-ELECTORAL-EXTRAORDINARIO",
      linkBoletin: "22",
    },
    {
      id: 23,
      monthBoletin: "ABR",
      nameBoletin:
        "boletín-23-ASUME-ITE-FUNCIONES-DE-CONSEJOS-MUNICIPALES-PARA-PROCESO-EXTRAORDINARIO",
      linkBoletin: "23",
    },
    {
      id: 24,
      monthBoletin: "ABR",
      nameBoletin:
        "BOLETÍN-24-PRESENTAN-ITE-Y-TEPJF-SEGUNDA-MESA-DE-DIÁLOGO-SOBRE-SISTEMA-NORMATIVO-INDÍGENA-Y-DE-PARTIDOS",
      linkBoletin: "24",
    },
    {
      id: 25,
      monthBoletin: "ABR",
      nameBoletin: "boletín-25-APRUEBA-ITE-TOPE-DE-GASTOS-DE-CAMPAÑA",
      linkBoletin: "25",
    },
    {
      id: 26,
      monthBoletin: "ABR",
      nameBoletin: "COMUNICADO-01",
      linkBoletin: "26",
    },
    {
      id: 27,
      monthBoletin: "MAY",
      nameBoletin:
        "boletín-26-IMPRIMIRÁ-“FORMAS-INTELIGENTES”-DOCUMENTACIÓN-ELECTORAL",
      linkBoletin: "27",
    },
    {
      id: 28,
      monthBoletin: "MAY",
      nameBoletin:
        "boletín-27-APRUEBA-ITE-MODELO-DE-RECEPCIÓN-DE-PAQUETES-ELECTORALES",
      linkBoletin: "28",
    },
    {
      id: 29,
      monthBoletin: "MAY",
      nameBoletin:
        "BOLETÍN-28-REQUIERE-ITE-A-PAN-PAC-Y-PT-CUMPLIR-CON-PARIDAD-DE-GÉNERO-EN-REGISTRO-DE-CANDIDATOS",
      linkBoletin: "29",
    },
    {
      id: 30,
      monthBoletin: "MAY",
      nameBoletin:
        "boletín-29-APRUEBA-ITE-MEDIDAS-DE-SEGURIDAD-DE-BOLETAS-ELECTORALES",
      linkBoletin: "30",
    },
    {
      id: 31,
      monthBoletin: "MAY",
      nameBoletin:
        "BOLETÍN-30-IMPULSAN-ITE-TET-E-IEM-OBSERVATORIO-DE-PARTICIPACIÓN-POLÍTICA-DE-LAS-MUJERES",
      linkBoletin: "31",
    },
    {
      id: 32,
      monthBoletin: "MAY",
      nameBoletin: "boletín-31-EFECTÚA-ITE-SIMULACROS-SOBRE-EL-PREP-Y-CÓMPUTOS",
      linkBoletin: "32",
    },
    {
      id: 33,
      monthBoletin: "MAY",
      nameBoletin:
        "BOLETÍN-32-EN-CUMPLIMIENTO-A-SENTENCIA-DE-TEPJ-MODIFICA-ITE-REGISTRO-DEL-PAC-EN-TEXOPA-XALTOCAN",
      linkBoletin: "33",
    },
    {
      id: 34,
      monthBoletin: "JUN",
      nameBoletin: "BOLETÍN-34-RECIBE-ITE-BOLETAS-DE-TEXOPA",
      linkBoletin: "34",
    },
    {
      id: 35,
      monthBoletin: "JUN",
      nameBoletin:
        "boletín-35-TODO-LISTO-PARA-LA-JORNADA-ELECTORAL-DE-ESTE-DOMINGO",
      linkBoletin: "35",
    },
    {
      id: 36,
      monthBoletin: "JUN",
      nameBoletin:
        "BOLETÍN-36-ITE-LLAMA-ITE-A-VOTAR-DE-MANERA-PACÍFICA-Y-ORDENADA",
      linkBoletin: "36",
    },
    {
      id: 37,
      monthBoletin: "JUN",
      nameBoletin:
        "BOLETÍN-37-ACUDEN-INTEGRANTES-DEL-CONSEJO-GENERAL-A-COLONIA-SANTA-MARTHA-XALOZTOC-A-VERIFICAR-AUTENTICIDAD-DE-BOLETAS-Y-LÍQUIDO-INDELEBLE",
      linkBoletin: "37",
    },
    {
      id: 38,
      monthBoletin: "JUN",
      nameBoletin: "BOLETÍN-38-CIERRAN-CASILLAS-SIN-INCIDENTES",
      linkBoletin: "38",
    },
    {
      id: 39,
      monthBoletin: "JUN",
      nameBoletin:
        "BOLETÍN-39-PARTICIPACIÓN-DE-66-EN-LAS-URNAS-ES-MUESTRA-DE-CONFIANZA-EN-ITE",
      linkBoletin: "39",
    },
    {
      id: 40,
      monthBoletin: "JUN",
      nameBoletin:
        "BOLETÍN-40-A-ESCRUTINIO-Y-CÓMPUTO-CASILLAS-DE-TEXOPA-Y-DE-BARRIO-DE-SANTIAGO",
      linkBoletin: "40",
    },
    {
      id: 41,
      monthBoletin: "JUN",
      nameBoletin:
        "BOLETÍN-41-DECLARA-ITE-VALIDEZ-DE-ELECCIÓN-EXTRAORDINARIA-2017-Y-ENTREGA-CONSTANCIAS",
      linkBoletin: "41",
    },
    {
      id: 42,
      monthBoletin: "JUN",
      nameBoletin: "BOLETÍN-42-APRUEBA-ITE-ACUERDO-PARA-RETIRAR-PROPAGANDA",
      linkBoletin: "42",
    },
    {
      id: 43,
      monthBoletin: "JUN",
      nameBoletin:
        "BOLETÍN-43-FISCALIZARÁ-ITE-A-AGRUPACIONES-QUE-PRETENDAN-CONSTITUIRSE-COMO-PARTIDO-POLÍTICO-LOCAL",
      linkBoletin: "43",
    },
    {
      id: 44,
      monthBoletin: "JUN",
      nameBoletin:
        "BOLETÍN-44-ABIERTA-CONVOCATORIA-PARA-PLAZAS-DEL-SERVICIO-PROFESIONAL-ELECTORAL-NACIONAL-EN-EL-ITE",
      linkBoletin: "44",
    },
    {
      id: 45,
      monthBoletin: "JUL",
      nameBoletin:
        "BOLETÍN-45-AJUSTA-ITE-PLAZOS-PARA-QUE-ORGANIZACIÓN-DE-CIUDADANOS-SIGA-PROCESO-DE-CONSTITUCIÓN-EN-PARTIDO",
      linkBoletin: "46",
    },
    {
      id: 46,
      monthBoletin: "JUL",
      nameBoletin:
        "17072017-BOLETÍN-46-ESTÁ-ITE-A-LA-ALTURA-DE-COMICIOS-PROFESIONALES-PRESIDENTA",
      linkBoletin: "45",
    },
    {
      id: 47,
      monthBoletin: "AGO",
      nameBoletin:
        "14082017-BOLETÍN-47-REVISAN-ITE-E-INE-LINEAMIENTOS-PARA-CÓMPUTOS-DE-PRÓXIMA-ELECCIÓN",
      linkBoletin: "47",
    },
    {
      id: 48,
      monthBoletin: "AGO",
      nameBoletin:
        "18082017-BOLETÍN-48-SE-PREPARA-ITE-PARA-ASAMBLEAS-DE-ORGANIZACIÓN-QUE-BUSCA-CONSTITUIRSE-EN-PARTIDO",
      linkBoletin: "48",
    },
    {
      id: 49,
      monthBoletin: "AGO",
      nameBoletin:
        "27082017-BOLETÍN-49-EFECTUARÁ-ITE-FORO-DE-DEFENSA-DE-DERECHOS-POLÍTICO-–ELECTORALES-DE-INDÍGENAS",
      linkBoletin: "49",
    },
    {
      id: 50,
      monthBoletin: "AGO",
      nameBoletin:
        "30082017-BOLETÍN-50-FORTALECE-DEMOCRACIA-PARTICIPACIÓN-Y-REPRESENTACIÓN-POLÍTICA-INDÍGENA-PIEDRAS",
      linkBoletin: "50",
    },
    {
      id: 51,
      monthBoletin: "SEP",
      nameBoletin:
        "10092017-BOLETÍN-51-SIGNAN-PIEDRAS-Y-CÓRDOVA-CONVENIO-PARA-PRÓXIMOS-COMICIOS",
      linkBoletin: "51",
    },
    {
      id: 52,
      monthBoletin: "SEP",
      nameBoletin:
        "12092017-BOLETÍN-52-ENTREVISTARÁ-ITE-A-ASPIRANTES-A-11-PLAZAS-DEL-SPEN",
      linkBoletin: "52",
    },
    {
      id: 53,
      monthBoletin: "SEP",
      nameBoletin:
        "29092017-BOLETÍN-ITE-53-APRUEBA-ITE-ANTEPROYECTO-DE-PRESUPUESTO-2018-APEGADO-A-NECESIDADES-DE-PROCESO-ELECTORAL",
      linkBoletin: "53",
    },
    {
      id: 54,
      monthBoletin: "OCT",
      nameBoletin:
        "12102017-BOLETÍN-54-DESIGNA-ITE-A-TITULARES-DE-DIRECCIONES-Y-ÁREAS-Y-ADECUA-COMISIONES",
      linkBoletin: "54",
    },
    {
      id: 55,
      monthBoletin: "OCT",
      nameBoletin:
        "20102017-BOLETÍN-55-APRUEBA-ITE-CONVOCATORIA-PARA-PROCESO-ELECTORAL-LOCAL-ORDINARIO-2018",
      linkBoletin: "55",
    },
    {
      id: 56,
      monthBoletin: "OCT",
      nameBoletin:
        "23102017-BOLETÍN-56-EFECTÚA-ITE-SORTEO-PARA-DEFINIR-ORDEN-DE-DISTRIBUCIÓN-DE-PROMOCIONALES-DE-RADIO-Y-TELEVISIÓN-A-PARTIDOS",
      linkBoletin: "56",
    },
    {
      id: 57,
      monthBoletin: "OCT",
      nameBoletin:
        "30102017-BOLETÍN-57-APRUEBA-ITE-INCORPORACIÓN-DE-PERSONAL-DEL-SPEN",
      linkBoletin: "57",
    },
    {
      id: 58,
      monthBoletin: "OCT",
      nameBoletin:
        "30102017-BOLETÍN-58-LA-PARIDAD-DE-GÉNERO-DEBE-SER-EFECTIVA-PIEDRAS",
      linkBoletin: "58",
    },
  ],

  2019: [
    {
      id: 1,
      monthBoletin: "ENE",
      nameBoletin: "01-comunidades",
      linkBoletin: "1",
    },
    {
      id: 2,
      monthBoletin: "ENE",
      nameBoletin: "02-lipeet",
      linkBoletin: "2",
    },
    {
      id: 3,
      monthBoletin: "ENE",
      nameBoletin: "02-prerrogativas y multas",
      linkBoletin: "3",
    },
    {
      id: 4,
      monthBoletin: "FEB",
      nameBoletin: "03-Presupuesto Egresos",
      linkBoletin: "4",
    },
    {
      id: 5,
      monthBoletin: "FEB",
      nameBoletin: "04-multas partidos",
      linkBoletin: "5",
    },
    {
      id: 6,
      monthBoletin: "FEB",
      nameBoletin: "04-programa capacitación",
      // linkBoletin: "6",
    },
    {
      id: 7,
      monthBoletin: "FEB",
      nameBoletin: "05-multas partidos",
      linkBoletin: "7",
    },
    {
      id: 8,
      monthBoletin: "MAR",
      nameBoletin: "06-inauguración sala de usos",
      linkBoletin: "8",
    },
    {
      id: 9,
      monthBoletin: "MAR",
      nameBoletin: "07-Paridad-Pta",
      linkBoletin: "9",
    },
    {
      id: 10,
      monthBoletin: "MAR",
      nameBoletin: "08-Ciclo conferencias",
      linkBoletin: "10",
    },
    {
      id: 11,
      monthBoletin: "MAR",
      nameBoletin: "09-Foro Violencia",
      linkBoletin: "11",
    },
    {
      id: 12,
      monthBoletin: "MAR",
      nameBoletin: "10-cumplimiento Ixcotla TEPJF",
      linkBoletin: "12",
    },
    {
      id: 13,
      monthBoletin: "MAR",
      nameBoletin: "11-retiro de propaganda",
      linkBoletin: "13",
    },
    {
      id: 14,
      monthBoletin: "ABR",
      nameBoletin: "12-parlamento infantil_1",
      linkBoletin: "14",
    },
    {
      id: 15,
      monthBoletin: "ABR",
      nameBoletin: "13-PES",
      linkBoletin: "15",
    },
    {
      id: 16,
      monthBoletin: "ABR",
      nameBoletin: "14-LIBROS ASTUDILLO",
      linkBoletin: "16",
    },
    {
      id: 17,
      monthBoletin: "ABR",
      nameBoletin: "15-Panel Observatorio",
      linkBoletin: "17",
    },
    {
      id: 18,
      monthBoletin: "ABR",
      nameBoletin: "16-Presentación cuento",
      // linkBoletin: "18",
    },
    {
      id: 19,
      monthBoletin: "ABR",
      nameBoletin: "17-ADECUACIÓN PRERROGATIVAS",
      // linkBoletin: "19",
    },
    {
      id: 20,
      monthBoletin: "MAY",
      nameBoletin: "18-Estudio de votos",
      linkBoletin: "20",
    },
    {
      id: 21,
      monthBoletin: "MAY",
      nameBoletin: "19-convenio UPET",
      linkBoletin: "21",
    },
    {
      id: 22,
      monthBoletin: "MAY",
      nameBoletin: "20- spen-evaluación",
      // linkBoletin: "22",
    },
    {
      id: 23,
      monthBoletin: "MAY",
      nameBoletin: "21- readecuación de Comisiones",
      // linkBoletin: "23",
    },
    {
      id: 24,
      monthBoletin: "JUN",
      nameBoletin: "21- readecuación de Comisiones",
      // linkBoletin: "24",
    },
    {
      id: 25,
      monthBoletin: "JUN",
      nameBoletin: "22-reformas electorales",
      linkBoletin: "25",
    },
    {
      id: 26,
      monthBoletin: "JUL",
      nameBoletin: "24-constancias parlamento",
      linkBoletin: "26",
    },
    {
      id: 27,
      monthBoletin: "JUL",
      nameBoletin: "25-destrucción boletas",
      // linkBoletin: "27",
    },
    {
      id: 28,
      monthBoletin: "JUL",
      nameBoletin: "26-dictámenes Impacto Social",
      // linkBoletin: "28",
    },
    {
      id: 29,
      monthBoletin: "JUL",
      nameBoletin: "27-Catálogo",
      // linkBoletin: "29",
    },
    {
      id: 30,
      monthBoletin: "AGO",
      nameBoletin: "28- Incentivos",
      linkBoletin: "30",
    },
    {
      id: 31,
      monthBoletin: "AGO",
      nameBoletin: "29- oficialía electoral",
      // linkBoletin: "31",
    },
    {
      id: 32,
      monthBoletin: "AGO",
      nameBoletin: "30- informe destrucción papelería",
      // linkBoletin: "32",
    },
    {
      id: 33,
      monthBoletin: "SEP",
      nameBoletin: "31-panel pueblos indígenas",
      // linkBoletin: "32",
    },

    {
      id: 34,
      monthBoletin: "SEP",
      nameBoletin: "32-foro transparencia",
      linkBoletin: "34",
    },
    {
      id: 35,
      monthBoletin: "SEP",
      nameBoletin: "33-actividad síndicas",
      // linkBoletin: "35",
    },
    {
      id: 36,
      monthBoletin: "SEP",
      nameBoletin: "34-Presupuesto 2020",
      linkBoletin: "36",
    },
    {
      id: 37,
      monthBoletin: "OCT",
      nameBoletin: "34-pláticas mujeres-",
      // linkBoletin: "37",
    },
    {
      id: 38,
      monthBoletin: "OCT",
      nameBoletin: "35-multas",
      linkBoletin: "38",
    },
    {
      id: 39,
      monthBoletin: "OCT",
      nameBoletin: "36-foro ciudadanía",
      // linkBoletin: "39",
    },
    {
      id: 40,
      monthBoletin: "OCT",
      nameBoletin: "37-Presentación de libro",
      // linkBoletin: "40",
    },
    {
      id: 41,
      monthBoletin: "OCT",
      nameBoletin: "38-ordinaria octubre",
      linkBoletin: "41",
    },
    {
      id: 42,
      monthBoletin: "NOV",
      nameBoletin: "39-sesión consultora",
      // linkBoletin: "42",
    },
    {
      id: 43,
      monthBoletin: "NOV",
      nameBoletin: "40-Concurso de carteles",
      linkBoletin: "43",
    },
    {
      id: 44,
      monthBoletin: "NOV",
      nameBoletin: "41-reglamento comité igualdad",
      // linkBoletin: "44",
    },
    {
      id: 45,
      monthBoletin: "NOV",
      nameBoletin: "42-EDU",
      linkBoletin: "45",
    },
    {
      id: 46,
      monthBoletin: "DIC",
      nameBoletin: "43-ALUMNOS FACEBOOK",
      linkBoletin: "46",
    },
    {
      id: 47,
      monthBoletin: "DIC",
      nameBoletin: "44- CURSO-TALLER DISCAPACIDAD",
      linkBoletin: "47",
    },
    {
      id: 48,
      monthBoletin: "DIC",
      nameBoletin: "45-presupuesto diputados",
      linkBoletin: "48",
    },
    {
      id: 49,
      monthBoletin: "DIC",
      nameBoletin: "46-comunidad Nanacamilpa",
      linkBoletin: "49",
    },
    {
      id: 50,
      monthBoletin: "DIC",
      nameBoletin: "47-resolución PRD",
      // linkBoletin: "50",
    },
    {
      id: 51,
      monthBoletin: "DIC",
      nameBoletin: "48-Informes comisiones",
      linkBoletin: "51",
    },
  ],

  2020: [
    {
      id: 1,
      monthBoletin: "ENE",
      nameBoletin: "Presupuesto.",
      linkBoletin: "1",
    },
    {
      id: 2,
      monthBoletin: "ENE",
      nameBoletin: "Informe.",
      linkBoletin: "2",
    },
    {
      id: 3,
      monthBoletin: "ENE",
      nameBoletin: "Sistema archivos",
      linkBoletin: "3",
    },
    {
      id: 4,
      monthBoletin: "FEB",
      nameBoletin: "Construcción",
      linkBoletin: "4",
    },
    {
      id: 5,
      monthBoletin: "FEB",
      nameBoletin: "Sesión ordinaria",
      linkBoletin: "5",
    },
    {
      id: 6,
      monthBoletin: "MAR",
      nameBoletin: "Construcción ciudadanía",
      linkBoletin: "6",
    },
    {
      id: 7,
      monthBoletin: "MAR",
      nameBoletin: "Medidas ante Covid 19",
      linkBoletin: "7",
    },
    {
      id: 8,
      monthBoletin: "ABR",
      nameBoletin: "Elimina presencial",
      linkBoletin: "8",
    },
    {
      id: 9,
      monthBoletin: "JUL",
      nameBoletin: "Fecha de inicio del PELO",
      linkBoletin: "9",
    },
    {
      id: 10,
      monthBoletin: "AGO",
      nameBoletin: "INICIA ITE RETORNO ESCALONADO A ACTIVIDADES PRESENCIALES",
      linkBoletin: "10",
    },
    {
      id: 11,
      monthBoletin: "AGO",
      nameBoletin: "INSTITUCIONES TIENEN DEUDA CON JÓVENES",
      linkBoletin: "11",
    },
    {
      id: 12,
      monthBoletin: "AGO",
      nameBoletin:
        "AJUSTA ITE PRESUPUESTO PARA ATENDER TEMAS DE SALUD DEL PERSONAL POR PANDEMIA",
      linkBoletin: "12",
    },
    {
      id: 13,
      monthBoletin: "AGO",
      nameBoletin:
        "POR AUSENCIA DE PARTES, SE DIFIERE AUDIENCIA DE DENUNCIA POR VIOLENCIA DE GÉNERO EN EL ITE",
      linkBoletin: "13",
    },
    {
      id: 14,
      monthBoletin: "AGO",
      nameBoletin: "Manual de Elecciones",
      linkBoletin: "14",
    },
    {
      id: 15,
      monthBoletin: "SEP",
      nameBoletin: "20-cotejo SPEN",
      linkBoletin: "15",
    },
    {
      id: 16,
      monthBoletin: "SEP",
      nameBoletin: "21- Instancia interna",
      linkBoletin: "16",
    },
    {
      id: 17,
      monthBoletin: "SEP",
      nameBoletin: "22 BOLETÍN 10-09-20 ITE",
      linkBoletin: "17",
    },
    {
      id: 18,
      monthBoletin: "SEP",
      nameBoletin: "23-semáforo amarillo",
      linkBoletin: "18",
    },
    {
      id: 19,
      monthBoletin: "SEP",
      nameBoletin: "24-reglamento usos y costumbres",
      linkBoletin: "19",
    },
    {
      id: 20,
      monthBoletin: "SEP",
      nameBoletin:
        "25- EDITA ITE CATÁLOGO DE PRESIDENCIAS DE COMUNIDAD QUE ELIGEN AUTORIDAD POR USOS Y COSTUMBRES",
      linkBoletin: "20",
    },
    {
      id: 21,
      monthBoletin: "SEP",
      nameBoletin: "26- Voces indigenas",
      linkBoletin: "21",
    },
    {
      id: 22,
      monthBoletin: "SEP",
      nameBoletin: "27- foro derecho a saber",
      linkBoletin: "22",
    },
    {
      id: 23,
      monthBoletin: "SEP",
      nameBoletin: "28- Presupuesto 2021",
      linkBoletin: "23",
    },
    {
      id: 24,
      monthBoletin: "SEP",
      nameBoletin: "29- Presupuesto 2021",
      linkBoletin: "24",
    },
    {
      id: 25,
      monthBoletin: "SEP",
      nameBoletin: "30-ITE",
      linkBoletin: "25",
    },
  ],

  2021: [
    {
      id: 1,
      monthBoletin: "ENE",
      nameBoletin:
        "APRUEBA ITE COALICIONES “UNIDOS POR TLAXCALA” Y “JUNTOS HAREMOS HISTORIA EN TLAXCALA” PARA LA GUBERNATURA",
      linkBoletin: "1",
    },
    {
      id: 2,
      monthBoletin: "ENE",
      nameBoletin:
        "APRUEBA ITE USO DE APLICACIÓN PARA CAPTAR APOYO CIUDADANO DE CANDIDATURAS INDEPENDIENTES",
      linkBoletin: "2",
    },
    {
      id: 3,
      monthBoletin: "ENE",
      nameBoletin:
        "AMPLÍA ITE PLAZO DE RECEPCIÓN DE SOLICITUDES PARA CONSEJOS DISTRITALES Y MUNICIPALES",
      linkBoletin: "3",
    },
    {
      id: 4,
      monthBoletin: "ENE",
      nameBoletin:
        "ORDENA ITE MEDIDAS CAUTELARES SOBRE ANUNCIOS DE INFORME ANUAL DE DIPUTADA",
      linkBoletin: "4",
    },
    {
      id: 5,
      monthBoletin: "ENE",
      nameBoletin:
        "05- DECLARA ITE INFUNDADOS PROCEDIMIENTOS CONTRA BRITO Y GARRIDO",
      linkBoletin: "5",
    },
    {
      id: 6,
      monthBoletin: "ENE",
      nameBoletin:
        "06- IMPARTE ITE CURSO VIRTUAL SOBRE PARIDAD LIBRE DE VIOLENCIA",
      linkBoletin: "6",
    },
    {
      id: 7,
      monthBoletin: "ENE",
      nameBoletin:
        "07- ACERCA ITE A ASPIRANTES TEMAS DE VIOLENCIA POLÍTICA EN RAZÓN DE GÉNERO Y LINEAMIENTOS DE PARIDAD",
      linkBoletin: "7",
    },
    {
      id: 8,
      monthBoletin: "ENE",
      nameBoletin:
        "08- DARÁ ITE VISTA A PGJE PARA QUE INVESTIGUE PROBABLE COMISIÓN DE DELITO DE VIOLENCIA POLÍTICA CONTRA SÍNDICA DE TOTOLAC (1)",
      linkBoletin: "8",
    },
    {
      id: 9,
      monthBoletin: "ENE",
      nameBoletin:
        "09- IMPROCEDENTE SUSPENDER CAPTACIÓN DE APOYO DE LA CIUDADANÍA PARA CANDIDATURA INDEPENDIENTE ITE",
      linkBoletin: "9",
    },
    {
      id: 10,
      monthBoletin: "ENE",
      nameBoletin:
        "10- CREA ITE SISTEMA ESTATAL DE PERSONAS SANCIONADAS EN MATERIA DE VIOLENCIA POLÍTICA CONTRA MUJERES EN RAZÓN DE GÉNERO",
      linkBoletin: "10",
    },
    {
      id: 11,
      monthBoletin: "FEB",
      nameBoletin:
        "11- APRUEBA OBSERVATORIO DE PARTICIPACIÓN POLÍTICA DE LAS MUJERES EN TLAXCALA PROGRAMA DE TRABAJO",
      linkBoletin: "11",
    },
    {
      id: 12,
      monthBoletin: "FEB",
      nameBoletin:
        "12 - FIRMAN ITE Y TET CONVENIO SOBRE REGISTRO DE PERSONAS SANCIONADAS POR VIOLENCIA POLÍTICA CONTRA MUJERES",
      linkBoletin: "12",
    },
    {
      id: 13,
      monthBoletin: "FEB",
      nameBoletin:
        "13 - ORDENA ITE RETIRAR ANUNCIOS PARTIDISTAS DE EQUIPAMIENTOS URBANOS",
      linkBoletin: "13",
    },
    {
      id: 14,
      monthBoletin: "FEB",
      nameBoletin:
        "14 - AUDITARÁ TECNOLÓGICO DE MONTERREY AL PREP DE ELECCIÓN LOCAL",
      linkBoletin: "14",
    },
    {
      id: 15,
      monthBoletin: "FEB",
      nameBoletin:
        "15 - HAY CONDICIONES PARA RECABAR APOYO DE LA CIUDADANÍA QUE REQUIEREN INDEPENDIENTES ITE",
      linkBoletin: "15",
    },
    {
      id: 16,
      monthBoletin: "FEB",
      nameBoletin:
        "16 - ITE ES TARDE PARA EMITIR MEDIDAS A FAVOR DE CANDIDATURAS DE COMUNIDAD LGBTTTIQ+",
      linkBoletin: "16",
    },
    {
      id: 17,
      monthBoletin: "FEB",
      nameBoletin:
        "17 - EMITIRÁ ITE LINEAMIENTOS PARA REGULAR ACTUACIÓN DE CONTENDIENTES QUE NO SE SEPAREN DEL CARGO",
      linkBoletin: "17",
    },
    {
      id: 18,
      monthBoletin: "MAR",
      nameBoletin:
        "18 - APRUEBA ITE LINEAMIENTOS PARA QUIENES CONTIENDAN EN ESTA ELECCIÓN SIN SEPARARSE DE SU CARGO",
      linkBoletin: "18",
    },
    {
      id: 19,
      monthBoletin: "MAR",
      nameBoletin:
        "19 - ALCANZAN 59 PERSONAS PORCENTAJE DE APOYO DE LA CIUDADANÍA PARA CANDIDATURAS INDEPENDIENTES",
      linkBoletin: "19",
    },
    {
      id: 20,
      monthBoletin: "MAR",
      nameBoletin:
        "20 - INSTALA ITE LOS 15 CONSEJOS DISTRITALES PARA EL PROCESO ELECTORAL LOCAL ORDINARIO",
      linkBoletin: "20",
    },
    {
      id: 21,
      monthBoletin: "MAR",
      nameBoletin: "21 - RATIFICA ITE INSTANCIA INTERNA ENCARGADA DEL PREP",
      linkBoletin: "21",
    },
    {
      id: 22,
      monthBoletin: "MAR",
      nameBoletin:
        "22 - RESUELVE ITE PROCEDIMIENTOS ORDINARIOS Y ESPECIALES SANCIONADORES",
      linkBoletin: "22",
    },
    {
      id: 23,
      monthBoletin: "MAR",
      nameBoletin:
        "23 - IMPLEMENTA ITE MECANISMO PARA QUE CIUDADANÍA OPINE SOBRE TEMAS PARA DEBATES A LA GUBERNATURA",
      linkBoletin: "23",
    },
    {
      id: 24,
      monthBoletin: "MAR",
      nameBoletin: "24 - MUNICIPIOS, RETO DE LA PARIDAD EN TLAXCALA COELLO",
      linkBoletin: "24",
    },
    {
      id: 25,
      monthBoletin: "MAR",
      nameBoletin: "25 - APRUEBA ITE AMPLIACIÓN DE CONVOCATORIA PARA CAES Y SE",
      linkBoletin: "25",
    },
    {
      id: 26,
      monthBoletin: "ABR",
      nameBoletin:
        "26 - EXHORTA ITE A HACER CAMPAÑAS DE PROPUESTAS Y SIN ARRIESGAR SALUD DE LA POBLACIÓN",
      linkBoletin: "26",
    },
    {
      id: 27,
      monthBoletin: "ABR",
      nameBoletin:
        "27 - 18 DE ABRIL A LAS 1900 HRS., PRIMER DEBATE ENTRE CANDIDATAS Y CANDIDATO A LA GUBERNATURA",
      linkBoletin: "27",
    },
    {
      id: 28,
      monthBoletin: "ABR",
      nameBoletin:
        "28 - REPRESENTANTES DE CANDIDATURAS AVALARON A LAS Y LOS MODERADORES DE DEBATES A GUBERNATURA",
      linkBoletin: "28",
    },
    {
      id: 29,
      monthBoletin: "ABR",
      nameBoletin:
        "29 - PRESENTARÁ ITE GUÍA DE ACTUACIÓN PARA PREVENCIÓN, ATENCIÓN Y",
      linkBoletin: "29",
    },
    {
      id: 30,
      monthBoletin: "MAY",
      nameBoletin:
        "30 - RESERVA ITE APROBACIÓN DE CANDIDATURAS A AYUNTAMIENTOS Y PC",
      linkBoletin: "30",
    },
    {
      id: 31,
      monthBoletin: "MAY",
      nameBoletin:
        "31 - REGISTRA ITE APROBACIÓN DE 18 MIL 503 SOLICITUDES DE REGISTRO PARA AYUNTAMIENTOS Y PC",
      linkBoletin: "31",
    },
    {
      id: 32,
      monthBoletin: "MAY",
      nameBoletin:
        "32 - DOMINGO 16 DE MAYO, SEGUNDO DEBATE ENTRE LAS CANDIDATAS Y EL",
      linkBoletin: "32",
    },
    {
      id: 33,
      monthBoletin: "JUN",
      nameBoletin:
        "35 - IMPROCEDENTE SUSTITUCIÓN DE LILIANA BECERRIL COMO CANDIDATA",
      linkBoletin: "33",
    },
    {
      id: 34,
      monthBoletin: "JUN",
      nameBoletin:
        "36 - LLAMA ITE A CUMPLIR CON EL DERECHO AL VOTO ESTE 6 DE JUNIO",
      linkBoletin: "34",
    },
    {
      id: 35,
      monthBoletin: "JUN",
      nameBoletin: "37 - LLAMA ITE A INCONFORMES DE XICOHTZINCO A PERMITIR QUE",
      linkBoletin: "35",
    },
    {
      id: 36,
      monthBoletin: "JUN",
      nameBoletin:
        "38 - DECLARA ITE QUE PEST, PS, PIS Y PES NO ALCANZARON 3% DE VOTACIÓN",
      linkBoletin: "36",
    },
    {
      id: 37,
      monthBoletin: "JUN",
      nameBoletin: "39 - HA LIQUIDADO ITE AL 80% DEL PERSONAL EVENTUAL",
      linkBoletin: "37",
    },
    {
      id: 38,
      monthBoletin: "JUL",
      nameBoletin: "41 - DESIGNA ITE A INTERVENTORES DE PARTIDOS",
      linkBoletin: "38",
    },
    {
      id: 39,
      monthBoletin: "JUL",
      nameBoletin:
        "42 - Suman INE y OPL esfuerzos para promover la Consulta Popular",
      linkBoletin: "39",
    },
    {
      id: 40,
      monthBoletin: "JUL",
      nameBoletin: "43 - DESAHOGA ITE RECUENTO EN UNA CASILLA DE ELECCIÓN DE",
      linkBoletin: "40",
    },
    {
      id: 41,
      monthBoletin: "AGO",
      nameBoletin: "44 - CONSTANCIAS PARLAMENTO",
      linkBoletin: "41",
    },
    {
      id: 42,
      monthBoletin: "AGO",
      nameBoletin: "45 - constancias de mayoría",
      linkBoletin: "42",
    },
    {
      id: 43,
      monthBoletin: "AGO",
      nameBoletin: "46 - capacitación mujeres",
      linkBoletin: "43",
    },
    {
      id: 44,
      monthBoletin: "AGO",
      nameBoletin: "47 - medidas Dulce Silva",
      linkBoletin: "44",
    },
    {
      id: 45,
      monthBoletin: "AGO",
      nameBoletin: "48 - candidaturas indígenas",
      linkBoletin: "45",
    },
    {
      id: 46,
      monthBoletin: "AGO",
      nameBoletin: "49 - observatorio conversatorio",
      linkBoletin: "46",
    },
    {
      id: 47,
      monthBoletin: "AGO",
      nameBoletin: "50 - observatorio sesión (1)",
      linkBoletin: "47",
    },
    {
      id: 48,
      monthBoletin: "AGO",
      nameBoletin: "51 - integración legislatura",
      linkBoletin: "48",
    },
    {
      id: 49,
      monthBoletin: "SEP",
      nameBoletin: "52 - foro derecho a saber",
      linkBoletin: "49",
    },
    {
      id: 50,
      monthBoletin: "SEP",
      nameBoletin: "53 - conclusión PELO (2)",
      linkBoletin: "50",
    },
    {
      id: 51,
      monthBoletin: "SEP",
      nameBoletin: "54 - pérdida de registro",
      linkBoletin: "51",
    },
    {
      id: 52,
      monthBoletin: "SEP",
      nameBoletin: "55 - Presupuesto",
      linkBoletin: "52",
    },
    {
      id: 53,
      monthBoletin: "OCT",
      nameBoletin: "56 - elecciones extraordinarias",
      linkBoletin: "53",
    },
    {
      id: 54,
      monthBoletin: "OCT",
      nameBoletin: "57 - cancelación de acreditación",
      linkBoletin: "54",
    },
    {
      id: 55,
      monthBoletin: "OCT",
      nameBoletin: "58 - Inicio PELE 2021",
      linkBoletin: "55",
    },
    {
      id: 56,
      monthBoletin: "OCT",
      nameBoletin: "59 - foro exgobernadoras",
      linkBoletin: "56",
    },
    {
      id: 57,
      monthBoletin: "OCT",
      nameBoletin: "60 - FORO PARTICIPACIÓN (1)",
      linkBoletin: "57",
    },
    {
      id: 58,
      monthBoletin: "OCT",
      nameBoletin: "61 - firma convenio",
      linkBoletin: "58",
    },
    {
      id: 59,
      monthBoletin: "OCT",
      nameBoletin: "62 - firma convenio CEDHT",
      linkBoletin: "59",
    },
    {
      id: 60,
      monthBoletin: "OCT",
      nameBoletin: "63 - RINDEN PROTESTA EL Y LAS NUEVAS CONSEJERAS DEL ITE",
      linkBoletin: "60",
    },
    {
      id: 61,
      monthBoletin: "NOV",
      nameBoletin: "64-adecuación",
      linkBoletin: "61",
    },
    {
      id: 62,
      monthBoletin: "NOV",
      nameBoletin: "65-documentación electoral",
      linkBoletin: "62",
    },
    {
      id: 63,
      monthBoletin: "NOV",
      nameBoletin: "67- registro de candidaturas",
      linkBoletin: "63",
    },
    {
      id: 64,
      monthBoletin: "NOV",
      nameBoletin:
        "68- APRUEBA ITE REGISTRO DE 27 FÓRMULAS QUE CONTENDERÁN EN EL PELE 2021",
      linkBoletin: "64",
    },
    {
      id: 65,
      monthBoletin: "NOV",
      nameBoletin:
        "69-APRUEBA ITE MEDIDAS DE SEGURIDAD DE BOLETAS PARA PROCESO EXTRAORDINARIO",
      linkBoletin: "65",
    },
    {
      id: 66,
      monthBoletin: "NOV",
      nameBoletin: "70-documentación electoral",
      linkBoletin: "66",
    },
    {
      id: 67,
      monthBoletin: "NOV",
      nameBoletin:
        "72- EFECTÚA ITE PRIMERA VERIFICACIÓN DE MEDIDAS DE SEGURIDAD EN BOLETAS",
      linkBoletin: "67",
    },
    {
      id: 68,
      monthBoletin: "NOV",
      nameBoletin:
        "73- SOTO PENDIENTE EN TLAXCALA NULIDAD DE ELECCIÓN POR VIOLENCIA",
      linkBoletin: "68",
    },
    {
      id: 69,
      monthBoletin: "NOV",
      nameBoletin:
        "74-LLAMA ITE A CIUDADANÍA DE COMUNIDADES QUE ELIGEN PRESIDENCIA A VOTAR EN PAZ SOCIAL",
      linkBoletin: "69",
    },
    {
      id: 70,
      monthBoletin: "NOV",
      nameBoletin:
        "75-VERIFICARÁN MEDIDAS DE SEGURIDAD DE BOLETAS EN CASILLA DE SANTA CRUZ GUADALUPE",
      linkBoletin: "70",
    },
    {
      id: 71,
      monthBoletin: "NOV",
      nameBoletin: "76- MODIFICA ITE REGLAMENTO DE CONSTITUCIÓN DE PARTIDOS",
      linkBoletin: "71",
    },
    {
      id: 72,
      monthBoletin: "NOV",
      nameBoletin: "77- EFECTUARÁ ITE CÓMPUTOS; APRUEBA COTEJOS Y RECUENTOS",
      linkBoletin: "72",
    },
    {
      id: 73,
      monthBoletin: "DIC",
      nameBoletin:
        "78- DECLARA ITE VALIDEZ DE ELECCIÓN EXTRAORDINARIA; ENTREGA CONSTANCIAS DE MAYORÍA",
      linkBoletin: "72",
    },
  ],

  2022: [
    {
      id: 1,
      monthBoletin: "ENE",
      nameBoletin:
        "BOLETÍN 01- DECLARA ITE CONCLUSIÓN DEL PROCESO ELECTORAL EXTRAORDINARIO",
      linkBoletin: "1",
    },
    {
      id: 2,
      monthBoletin: "ENE",
      nameBoletin:
        "BOLETÍN 02- APRUEBA ITE RETENER 6.2 MDP A PARTIDOS POR MULTAS.",
      linkBoletin: "2",
    },
    {
      id: 3,
      monthBoletin: "ENE",
      nameBoletin:
        "BOLETÍN 03 - INICIA ITE ESTE MIÉRCOLES CONCURSO DE DEBATE PARA JÓVENES.",
      linkBoletin: "3",
    },
    {
      id: 4,
      monthBoletin: "ENE",
      nameBoletin:
        "BOLETÍN 04.- DECLARA ITE PROCEDENTE REGISTRO DE RSP COMO PARTIDO LOCAL.",
      linkBoletin: "4",
    },
    {
      id: 5,
      monthBoletin: "ENE",
      nameBoletin:
        "05.- APRUEBA ITE REGISTRO DE FUERZA POR MÉXICO COMO PARTIDO POLÍTICO LOCAL.",
      linkBoletin: "5",
    },
    {
      id: 6,
      monthBoletin: "FEB",
      nameBoletin:
        "BOLETÍN 06.- APRUEBA ITE READECUACIÓN DE PRERROGATIVAS DE PARTIDOS POLÍTICOS.",
      linkBoletin: "6",
    },
    {
      id: 7,
      monthBoletin: "FEB",
      nameBoletin:
        "BOLETÍN 06.- APRUEBA ITE READECUACIÓN DE PRERROGATIVAS DE PARTIDOS POLÍTICOS.",
      linkBoletin: "7",
    },
    {
      id: 8,
      monthBoletin: "FEB",
      nameBoletin:
        "BOLETÍN 06.- APRUEBA ITE READECUACIÓN DE PRERROGATIVAS DE PARTIDOS POLÍTICOS.",
      linkBoletin: "8",
    },
    {
      id: 9,
      monthBoletin: "FEB",
      nameBoletin:
        "BOLETÍN 06.- APRUEBA ITE READECUACIÓN DE PRERROGATIVAS DE PARTIDOS POLÍTICOS.",
      linkBoletin: "9",
    },
    {
      id: 10,
      monthBoletin: "FEB",
      nameBoletin:
        "BOLETÍN 06.- APRUEBA ITE READECUACIÓN DE PRERROGATIVAS DE PARTIDOS POLÍTICOS.",
      linkBoletin: "10",
    },
    {
      id: 11,
      monthBoletin: "MAR",
      nameBoletin:
        "BOLETÍN 11-APRUEBA ITE DESTRUCCIÓN DE DOCUMENTACIÓN DEL PELE 2021.",
      linkBoletin: "11",
    },
    {
      id: 12,
      monthBoletin: "MAR",
      nameBoletin: "BOLETÍN 12 CREA ITE COORDINACIÓN DE GÉNERO",
      linkBoletin: "12",
    },
    {
      id: 13,
      monthBoletin: "ABR",
      nameBoletin:
        "BOLETÍN 13 DONA ITE 25 TONELADAS DE DOCUMENTACIÓN ELECTORAL A CONALITEG",
      linkBoletin: "13",
    },
    {
      id: 14,
      monthBoletin: "ABR",
      nameBoletin:
        "BOLETÍN 14 FIRMAN CONVENIO ITE Y UATx PARA DIFUNDIR INFORMACIÓN EN NÁHUATL Y OTOMÍ",
      linkBoletin: "14",
    },
    {
      id: 15,
      monthBoletin: "ABR",
      nameBoletin:
        "BOLETÍN 15 FIRMAN CONVENIO ITE Y SECRETARIA DE BIENESTAR PARA DESARROLLO DE CONSULTA A PUEBLOS INDÍGENAS",
      linkBoletin: "15",
    },
    {
      id: 16,
      monthBoletin: "ABR",
      nameBoletin:
        "BOLETÍN 16 FIRMAN CONVENIO ITE Y CEDHT PARA MATERIALIZAR CONSULTA A PUEBLOS INDÍGENAS",
      linkBoletin: "16",
    },
    {
      id: 17,
      monthBoletin: "ABR",
      nameBoletin:
        "BOLETÍN 17 FIRMAN CONVENIO ITE E INPI PARA CONSULTA DE PUEBLOS INDÍGENAS",
      linkBoletin: "17",
    },
    {
      id: 18,
      monthBoletin: "ABR",
      nameBoletin:
        "BOLETÍN 18 ELIGEN A INTEGRANTES DEL DÉCIMO TERCER PARLAMENTO INFANTIL POR PRINCIPIO DE MAYORÍA",
      linkBoletin: "18",
    },
    {
      id: 19,
      monthBoletin: "ABR",
      nameBoletin:
        "BOLETÍN 19. ASESORA ITE A AYUNTAMIENTOS EN EJERCICIO DE “VOZ CIUDADANA EN EL CABILDO”",
      linkBoletin: "19",
    },
    {
      id: 20,
      monthBoletin: "MAY",
      nameBoletin: "BOLETÍN 20 INICIA ITE DESARROLLO DE ASAMBLEAS",
      linkBoletin: "20",
    },
    {
      id: 21,
      monthBoletin: "MAY",
      nameBoletin:
        "BOLETÍN 21 SOLICITA ITE A CONGRESO DESINCORPORACIÓN DE VEHÍCULOS EN DESUSO",
      linkBoletin: "21",
    },
    {
      id: 22,
      monthBoletin: "MAY",
      nameBoletin:
        "BOLETÍN 22 APRUEBA ITE PROTOCOLOS PARA LA CONSULTA CIUDADANA A PUEBLOS Y COMUNIDADES INDÍGENAS",
      linkBoletin: "22",
    },
    {
      id: 23,
      monthBoletin: "JUN",
      nameBoletin:
        "BOLETÍN 23. APRUEBA ITE MEDIDAS CAUTELARES Y DE PROTECCIÓN A REGIDORA Y SÍNDICA EN DENUNCIA POR VPMrG",
      linkBoletin: "23",
    },
    {
      id: 24,
      monthBoletin: "JUN",
      nameBoletin:
        "BOLETÍN 24. APRUEBA ITE CAMBIO DE DENOMINACIÓN A ORGANIZACIÓN QUE PRETENDE CONVERTIRSE EN PARTIDO",
      linkBoletin: "24",
    },
    {
      id: 25,
      monthBoletin: "JUN",
      nameBoletin:
        "BOLETÍN 25-MOVIMIENTO LABORISTA NO PODRÁ CONTINUAR PROCESO PARA CONSTITUIRSE EN PARTIDO",
      linkBoletin: "25",
    },
    {
      id: 26,
      monthBoletin: "JUN",
      nameBoletin:
        "BOLETÍN 26-DESINCORPORA ITE APROXIMADAMENTE 10 MIL KG DE MATERIAL ELECTORAL PARA DESTRUCCIÓN",
      linkBoletin: "26",
    },
    {
      id: 27,
      monthBoletin: "JUN",
      nameBoletin: "BOLETÍN 27 PRESENTA OIC DEL ITE CÓDIGO DE ÉTICA",
      linkBoletin: "27",
    },
    {
      id: 28,
      monthBoletin: "JUN",
      nameBoletin:
        "BOLETÍN 28. RECABA ITE POSTURA DE COMUNIDAD LGBTTTIQ PARA PROPUESTA DE REFORMA ELECTORAL",
      linkBoletin: "28",
    },
    {
      id: 29,
      monthBoletin: "JUN",
      nameBoletin:
        "BOLETÍN 29 FORMULA ITE NUEVO REQUERIMIENTO A ORGANZACIONES QUE PRETENDEN CONSTITUIRSE COMO PARTIDO",
      linkBoletin: "29",
    },
    {
      id: 30,
      monthBoletin: "JUN",
      nameBoletin:
        "BOLETÍN 30-FIRMA ITE CONVENIO CON 12 AYUNTAMIENTOS PARA FORTALECER CULTURA DEMOCRÁTICA",
      linkBoletin: "30",
    },
    {
      id: 31,
      monthBoletin: "JUN",
      nameBoletin:
        "BOLETÍN 31. CREA ITE coordinación de sistemas pluriculturales",
      linkBoletin: "31",
    },
    {
      id: 32,
      monthBoletin: "JUL",
      nameBoletin:
        "BOLETÍN 32 A DESTRUCCIÓN YO RECICLAJE 41 TONS DE DOCUMENTACIÓN Y MATERIAL ELECTORAL LOCAL",
      linkBoletin: "32",
    },
    {
      id: 33,
      monthBoletin: "AGO",
      nameBoletin:
        "BOLETÍN 33. CREA ITE MICROSITIO SOBRE PARTICIPACIÓN DE MUJERES",
      linkBoletin: "33",
    },
    {
      id: 34,
      monthBoletin: "AGO",
      nameBoletin:
        "BOLETÍN 34-LLEVARÁ A CABO ITE SEMANA DE LA EDUCACIÓN CÍVICA",
      linkBoletin: "34",
    },
    {
      id: 35,
      monthBoletin: "SEP",
      nameBoletin:
        "BOLETÍN 35 -SATISFECHA DE SERVIR A TLAXCALA DEJA CARGO PRESIDENTA DEL ITE ELIZABETH PIEDRAS",
      linkBoletin: "35",
    },
    {
      id: 36,
      monthBoletin: "SEP",
      nameBoletin:
        "BOLETÍN 36 ELIGEN CONSEJERAS Y CONSEJEROS ELECTORALES A JUAN CARLOS MINOR MÁRQUEZ COMO PRESIDENTE PROVISIONAL DEL ITE",
      linkBoletin: "36",
    },
    {
      id: 37,
      monthBoletin: "SEP",
      nameBoletin:
        "BOLETÍN 37 - PARTICIPACIÓN CIUDADANA, FUNDAMENTAL PARA DEMOCRACIA DE CALIDAD-JCMM",
      linkBoletin: "37",
    },
    {
      id: 38,
      monthBoletin: "SEP",
      nameBoletin:
        "BOLETÍN 38 - SIGNA ITE CONVENIOS CON AYUNTAMIENTOS Y SECTOR EDUCATIVO EN MATERIA DE EDUCACIÓN CÍVICA",
      linkBoletin: "38",
    },
    {
      id: 39,
      monthBoletin: "SEP",
      nameBoletin:
        "BOLETÍN 39 AÚN HAY RETOS PARA MEJORAR CALIDAD DE CIUDADANÍA_ FAZ MORA",
      linkBoletin: "39",
    },
    {
      id: 40,
      monthBoletin: "SEP",
      nameBoletin: "BOLETÍN 40 CONCLUYE ITE SEMANA DE EDUCACIÓN CÍVICA",
      linkBoletin: "40",
    },
    {
      id: 41,
      monthBoletin: "SEP",
      nameBoletin:
        "BOLETÍN 41-ATENDIÓ ITE 360 EJERCICIOS DE ASAMBLEAS EN PROCESO DE CONSTITUCIÓN DE PARTIDOS LOCALES",
      linkBoletin: "41",
    },
    {
      id: 42,
      monthBoletin: "SEP",
      nameBoletin:
        "BOLETÍN 42-RINDE PROTESTA JUAN CARLOS MINOR COMO PRESIDENTE PROVISIONAL DEL ITE",
      linkBoletin: "42",
    },
    {
      id: 43,
      monthBoletin: "SEP",
      nameBoletin:
        "BOLETÍN 43- SOLICITARÁ ITE 52.9 MDP DE PRESUPUESTO PARA 2023; PARTIDOS TENDRÁN 63.9 MDP",
      linkBoletin: "43",
    },
    {
      id: 44,
      monthBoletin: "OCT",
      nameBoletin:
        "BOLETÍN 44 DICTARÁ SARA LOVERA CONFERENCIA EN TLAXCALA SOBRE CIUDADANÍA DE MUJERES",
      linkBoletin: "44",
    },
    {
      id: 45,
      monthBoletin: "OCT",
      nameBoletin:
        "BOLETÍN 45 CAPITALIZAR DIFERENCIAS DE MUJERES EN BENEFICIO DEL FEMINISMO, PROPONE SARA LOVERA",
      linkBoletin: "45",
    },
    {
      id: 46,
      monthBoletin: "OCT",
      nameBoletin:
        "BOLETÍN 46. DE LARGO ALIENTO ESTRATEGIA DE IMPULSO A PARTICIPACIÓN DE MUJERES",
      linkBoletin: "46",
    },
    {
      id: 47,
      monthBoletin: "NOV",
      nameBoletin:
        "BOLETÍN 47 ENTREGA ITE PREMIO A LUIS FERNANDO ALCOLTZI GANADOR DE LA ETAPA ESTATAL EN EL CONCURSO NACIONAL DE ORATORIA 2022",
      linkBoletin: "47",
    },
    {
      id: 48,
      monthBoletin: "NOV",
      nameBoletin:
        "BOLETÍN 48 EJERCER CARGOS SIN VIOLENCIA ES UN DERECHO-GARAY",
      linkBoletin: "48",
    },
  ],

  2023: [
    {
      id: 1,
      monthBoletin: "ENE",
      nameBoletin: "BOLETÍN 01- ACTUALIZA ITE MULTAS A PARTIDOS PARA 2023",
      linkBoletin: "1",
    },
    {
      id: 2,
      monthBoletin: "ENE",
      nameBoletin:
        "BOLETÍN 02- Trabajo conjunto, clave en impulso a participación de juventudes, coinciden panelistsa",
      linkBoletin: "2",
    },
    {
      id: 3,
      monthBoletin: "ENE",
      nameBoletin:
        "BOLETÍN 03- RINDE PROTESTA EMMANUEL ÁVILA GONZÁLEZ COMO PRESIDENTE DEL ITE",
      linkBoletin: "3",
    },
    {
      id: 4,
      monthBoletin: "FEB",
      nameBoletin: "BOLETÍN 04- EN MAL ESTADO 70% DEL PARQUE VEHICULAR DEL ITE",
      linkBoletin: "4",
    },
    {
      id: 5,
      monthBoletin: "FEB",
      nameBoletin:
        "BOLETÍN 05- REFUERZA ITE ACCIONES DE IMPULSO A PARTICIPACIÓN JUVENIL",
      linkBoletin: "5",
    },
    {
      id: 6,
      monthBoletin: "FEB",
      nameBoletin:
        "BOLETÍN 06- Boletín 006-ABRE ITE CONVOCATORIA PARA OBSERVACIÓN EN CONSULTA A COMUNIDADADES",
      linkBoletin: "6",
    },
  ],
};
