export const dataCIndex = [
  {
    id: 1,
    imgUrl: "distritacion.jpg",
    alt: "Distritación Electoral Local Tlaxcala 2022",
    url: "",
    linkToo: "/Mapas",
  },
  {
    id: 2,
    imgUrl: "guiaViolencia.jpg",
    alt: "Guia de actuación",
    url: "https://itetlax.org.mx/assets/pdf/acuerdos/ITE/2022/64.1.pdf",
    linkToo: "",
  },
  {
    id: 3,
    imgUrl: "libroITE.jpg",
    alt: "Libro ITE",
    url: "https://itetlax.org.mx/assets/pdf/carousel/libroITE.pdf",
    linkToo: "",
  },
  {
    id: 4,
    imgUrl: "libroOPPMT.jpg",
    alt: "Libro OPPMT",
    url: "https://itetlax.org.mx/assets/pdf/carousel/libroOPPMT.pdf",
    linkToo: "",
  },
  {
    id: 5,
    imgUrl: "memElectoral.jpg",
    alt: "Memoria Electoral 2021",
    url: "https://itetlax.org.mx/assets/pdf/memElect/11.pdf",
    linkToo: "",
  },
  {
    id: 6,
    imgUrl: "comunidades.jpeg",
    alt: "micrositioComunidades",
    url: "https://ite-comunidades.itetlax.org.mx",
    linkToo: "",
  },
  {
    id: 7,
    imgUrl: "ite-conecta.jpg",
    alt: "ITE Conecta",
    url: "",
    linkToo: "/ITEConecta",
  },
  {
    id: 8,
    imgUrl: "accionesAfirmativas.png",
    alt: "Acciones Afirmativas",
    url: "https://ite-consulta-pcd.itetlax.org.mx/",
    linkToo: "",
  },
  {
    id: 9,
    imgUrl: "actores-politicos.jpg",
    alt: "Actores Politicos",
    url: "https://itetlax.org.mx/assets/pdf/comunicacion/lineamientos_radio_television.pdf",
    linkToo: "",
  },
  {
    id: 10,
    imgUrl: "consultas.jpg",
    alt: "Consultas Indigenas",
    url: "https://ite-consulta-pcd.itetlax.org.mx/pages/pi/",
    linkToo: "",
  },
  {
    id: 11,
    imgUrl: "convocatoria_revista_AMCEE_.jpg",
    alt: "56",
    url: "https://amcee.org.mx/wp-content/uploads/2024/04/5ta-convocatoria-AMCEE.pdf",
    linkToo: "",
  },
  {
    id: 12,
    imgUrl: "convocatoria_oratoria.png",
    alt: "56",
    itemActive: "active",
    buttonActive: "active",
    ariaCurrent: "true",
    url: "https://itetlax.org.mx/assets/img/imgC_I/convocatoria_oratoria.pdf",
    linkToo: "",
  },
];

export const dataCAR = [
  {
    id: 1,
    imgUrl: "0.png",
    alt: "Convocatoria OPPMT",
    url: "",
    linkToo: "",
  },
  {
    id: 2,
    imgUrl: "11.png",
    alt: "siceen21",
    url: "https://siceen21.ine.mx/home",
    linkToo: "",
  },
  {
    id: 2,
    imgUrl: "apoyo_ciudadano.png",
    itemActive: "active",
    buttonActive: "active",
    ariaCurrent: "true",
    alt: "Convocatoria",
    url: "",
    linkToo: "",
  },
];

export const dataCA = [
  {
    id: 1,
    imgUrl: "7.jpg",
    alt: "Participación Política de las mujeres tlaxcaltecas",
    url: "",
    linkToo: "",
  },
  {
    id: 2,
    imgUrl: "46.jpg",
    alt: "Faro Democrático",
    url: "https://farodemocratico.ine.mx/",
    linkToo: "",
  },
  {
    id: 3,
    imgUrl: "47.jpg",
    alt: "Faro Democrático",
    url: "https://farodemocratico.ine.mx/",
    linkToo: "",
  },
  {
    id: 4,
    imgUrl: "48.jpg",
    alt: "Faro Democrático",
    url: "https://farodemocratico.ine.mx/",
    linkToo: "",
  },
  {
    id: 5,
    imgUrl: "49.jpg",
    alt: "Faro Democrático",
    url: "https://farodemocratico.ine.mx/",
    linkToo: "",
  },
  {
    id: 6,
    imgUrl: "50.jpg",
    alt: "Faro Democrático",
    itemActive: "active",
    buttonActive: "active",
    ariaCurrent: "true",
    url: "https://farodemocratico.ine.mx/",
    linkToo: "",
  },
];
