export const dataMonitoreos = {
  2015: [
    {
      id: 1,
      monthBoletin: "NOV",
      nameBoletin: "primer-informe-4-al-15-de-noviembre-de-2015",
      linkBoletin: "1",
    },
    {
      id: 2,
      monthBoletin: "NOV",
      nameBoletin: "Comunicado 001 APRUEBA ITE REGLAMENTO DE SESIONES",
      linkBoletin: "2",
    },
    {
      id: 3,
      monthBoletin: "DIC",
      nameBoletin: "tercer-informe-30-al-13-de-diciembre-de-2015",
      linkBoletin: "3",
    },
    {
      id: 4,
      monthBoletin: "DIC",
      nameBoletin: "cuarto-informe-14-al-27-de-diciembre-de-2015",
      linkBoletin: "4",
    },
    {
      id: 5,
      monthBoletin: "DIC",
      nameBoletin: "quinto-informe-28-de-diciembre-al-10-de-enero-de-2016",
      linkBoletin: "5",
    },
  ],

  2016: [
    {
      id: 1,
      monthBoletin: "ENE",
      nameBoletin: "1ER.-INFORME-CONCENTRADO-MENSUAL",
      linkBoletin: "1",
    },
    {
      id: 2,
      monthBoletin: "ENE",
      nameBoletin: "sexto-informe-11-al-24-de-enero-de-2016",
      linkBoletin: "2",
    },
    {
      id: 3,
      monthBoletin: "ENE",
      nameBoletin: "séptimo-informe-25-de-enero-al-7-de-febrero-de-2016",
      linkBoletin: "3",
    },
    {
      id: 4,
      monthBoletin: "FEB",
      nameBoletin: "2DO.-INFORME-CONCENTRADO-MENSUAL",
      linkBoletin: "4",
    },
    {
      id: 5,
      monthBoletin: "FEB",
      nameBoletin: "Noveno Informe (22 de febrero al 6 de marzo)",
      linkBoletin: "5",
    },
    {
      id: 6,
      monthBoletin: "FEB",
      nameBoletin: "octavo informe (8 al 21 de febrero de 2016)",
      linkBoletin: "6",
    },
    {
      id: 7,
      monthBoletin: "MAR",
      nameBoletin: "3ER.-INFORME-CONCENTRADO-MENSUAL",
      linkBoletin: "7",
    },
    {
      id: 8,
      monthBoletin: "MAR",
      nameBoletin: "DECIMO-INFORME 07-20 marzo",
      linkBoletin: "8",
    },
    {
      id: 9,
      monthBoletin: "MAR",
      nameBoletin: "DECIMO-PRIMER-INFORME 21-03 ABRIL",
      linkBoletin: "9",
    },
    {
      id: 10,
      monthBoletin: "ABR",
      nameBoletin: "4TO.-INFORME-CONCENTRADO-MENSUAL",
      linkBoletin: "10",
    },
    {
      id: 11,
      monthBoletin: "ABR",
      nameBoletin: "DECIMO-SEGUNDO-INFORME",
      linkBoletin: "11",
    },
    {
      id: 12,
      monthBoletin: "ABR",
      nameBoletin: "DECIMO-TERCER-INFORME",
      linkBoletin: "12",
    },
    {
      id: 13,
      monthBoletin: "MAY",
      nameBoletin: "5TO.-INFORME-CONCENTRADO-MENSUAL",
      linkBoletin: "13",
    },
    {
      id: 14,
      monthBoletin: "MAY",
      nameBoletin: "DECIMO-CUARTO-INFORME-MONITOREO",
      linkBoletin: "14",
    },
    {
      id: 15,
      monthBoletin: "MAY",
      nameBoletin: "INFORME-DECIMO-QUINTO",
      linkBoletin: "15",
    },
    {
      id: 16,
      monthBoletin: "JUN",
      nameBoletin: "INFORME-DECIMO-SEXTO",
      linkBoletin: "16",
    },
  ],

  2017: [
    {
      id: 1,
      monthBoletin: "MAR",
      nameBoletin: "PRIMER-INFORME-MENSUAL",
      linkBoletin: "1",
    },
    {
      id: 2,
      monthBoletin: "MAR",
      nameBoletin: "PRIMER-INFORME-QUINCENAL",
      linkBoletin: "2",
    },
    {
      id: 3,
      monthBoletin: "ABR",
      nameBoletin: "INFORME-SEGUNDO-MONITOREO",
      linkBoletin: "3",
    },
    {
      id: 4,
      monthBoletin: "MAY",
      nameBoletin: "INFORME QUINTO MONITOREO",
      linkBoletin: "4",
    },
    {
      id: 5,
      monthBoletin: "MAY",
      nameBoletin: "INFORME-CUARTO-MONITOREO",
      linkBoletin: "5",
    },
    {
      id: 6,
      monthBoletin: "MAY",
      nameBoletin: "TERCER INFORME MENSUAL",
      linkBoletin: "6",
    },
    {
      id: 7,
      monthBoletin: "JUN",
      nameBoletin: "INFORME-FINAL-MONITOREO",
      linkBoletin: "7",
    },
    {
      id: 8,
      monthBoletin: "JUN",
      nameBoletin: "INFORME-SEXTO-MONITOREO",
      linkBoletin: "8",
    },
    {
      id: 9,
      monthBoletin: "DIC",
      nameBoletin: "Primer-Informe-Quincenal-PELO-2018",
      linkBoletin: "9",
    },
    {
      id: 10,
      monthBoletin: "DIC",
      nameBoletin: "Segundo-Informe-Quincenal-PELO-2018",
      linkBoletin: "10",
    },
  ],

  2018: [
    {
      id: 1,
      monthBoletin: "ENE",
      nameBoletin: "Tercer-Informe-Quincenal-PELO-2018",
      linkBoletin: "1",
    },
    {
      id: 2,
      monthBoletin: "ENE",
      nameBoletin: "1er Informe de monitoreo de radio y televisión",
      linkBoletin: "12",
    },
    {
      id: 3,
      monthBoletin: "ENE",
      nameBoletin: "2do Informe de monitoreo de radio y televisión",
      linkBoletin: "13",
    },
    {
      id: 4,
      monthBoletin: "FEB",
      nameBoletin: "Sexto-Informe-Quincenal-PELO-2018",
      linkBoletin: "2",
    },
    {
      id: 5,
      monthBoletin: "MAR",
      nameBoletin: "Septimo-Informe-Quincenal-PELO-2018",
      linkBoletin: "3",
    },
    {
      id: 6,
      monthBoletin: "ABR",
      nameBoletin: "Octavo-informe-Quincenal-PELO-2018",
      linkBoletin: "4",
    },
    {
      id: 7,
      monthBoletin: "ABR",
      nameBoletin: "Noveno-informe-Quincenal-PELO-2018",
      linkBoletin: "5",
    },
    {
      id: 8,
      monthBoletin: "ABR",
      nameBoletin: "Decimo-Informe-Quincenal-PELO-2018",
      linkBoletin: "6",
    },
    {
      id: 9,
      monthBoletin: "MAY",
      nameBoletin: "Onceavo-Informe-Quincenal-PELO-2018",
      linkBoletin: "7",
    },
    {
      id: 10,
      monthBoletin: "MAY",
      nameBoletin: "Doceavo Informe Quincenal PELO 2018",
      linkBoletin: "8",
    },
    {
      id: 11,
      monthBoletin: "MAY",
      nameBoletin: "Treceavo Informe Quincenal PELO 2018",
      linkBoletin: "9",
    },
    {
      id: 12,
      monthBoletin: "JUN",
      nameBoletin: "Catorceavo Informe Quincenal PELO 2018",
      linkBoletin: "10",
    },
    {
      id: 13,
      monthBoletin: "JUL",
      nameBoletin: "Informe final PELO 2018",
      linkBoletin: "11",
    },
  ],

  2020: [
    {
      id: 1,
      monthBoletin: "NOV",
      nameBoletin: "Tercer-Informe-Quincenal-PELO-2018",
      linkBoletin: "1",
    },
    {
      id: 2,
      monthBoletin: "NOV",
      nameBoletin: "PRIMER INFORME MONITOREO MIyD PELO 2020-2021",
      linkBoletin: "6",
    },
    {
      id: 3,
      monthBoletin: "NOV",
      nameBoletin: "SEGUNDO INFORME MONITOREO MIyD",
      linkBoletin: "7",
    },
    {
      id: 4,
      monthBoletin: "DIC",
      nameBoletin: "1er Informe de monitoreo con perspectiva de género",
      linkBoletin: "11",
    },
    {
      id: 5,
      monthBoletin: "DIC",
      nameBoletin: "CUARTO INFORME MONITOREO MIyD",
      linkBoletin: "2",
    },
    {
      id: 6,
      monthBoletin: "DIC",
      nameBoletin: "PRIMER INFORME MONITOREO PdG",
      linkBoletin: "3",
    },
    {
      id: 7,
      monthBoletin: "DIC",
      nameBoletin: "PRIMER INFORME MONITOREO RyT",
      linkBoletin: "4",
    },
    {
      id: 8,
      monthBoletin: "DIC",
      nameBoletin: "TERCER INFORME MONITOREO MIyD",
      linkBoletin: "5",
    },
    {
      id: 9,
      monthBoletin: "DIC",
      nameBoletin: "TERCER INFORME MONITOREO MIyD",
      linkBoletin: "8",
    },
    {
      id: 10,
      monthBoletin: "DIC",
      nameBoletin: "CUARTO INFORME MONITOREO MIyD",
      linkBoletin: "9",
    },
    {
      id: 11,
      monthBoletin: "DIC",
      nameBoletin: "1er Informe de monitoreo de radio y televisión",
      linkBoletin: "10",
    },
  ],

  2021: [
    {
      id: 1,
      monthBoletin: "ENE",
      nameBoletin: "QUINTO INFORME MONITOREO MIyD",
      linkBoletin: "1",
    },
    {
      id: 2,
      monthBoletin: "ENE",
      nameBoletin: "SEGUNDO INFORME MONITOREO PdG",
      linkBoletin: "2",
    },
    {
      id: 3,
      monthBoletin: "ENE",
      nameBoletin: "SEGUNDO INFORME MONITOREO RyT",
      linkBoletin: "3",
    },
    {
      id: 4,
      monthBoletin: "ENE",
      nameBoletin: "SEXTO INFORME MONITOREO MIyD",
      linkBoletin: "4",
    },
    {
      id: 5,
      monthBoletin: "ENE",
      nameBoletin: "TERCER INFORME MONITOREO PdG",
      linkBoletin: "5",
    },
    {
      id: 6,
      monthBoletin: "ENE",
      nameBoletin: "TERCER INFORME MONITOREO RyT",
      linkBoletin: "6",
    },
    {
      id: 7,
      monthBoletin: "ENE",
      nameBoletin: "QUINTO INFORME MONITOREO",
      linkBoletin: "11",
    },
    {
      id: 8,
      monthBoletin: "ENE",
      nameBoletin: "SEXTO INFORME MONITOREO",
      linkBoletin: "12",
    },
    {
      id: 9,
      monthBoletin: "ENE",
      nameBoletin: "SEGUNDO INFORME MONITOREO",
      linkBoletin: "27",
    },
    {
      id: 10,
      monthBoletin: "ENE",
      nameBoletin: "TERCER INFORME MONITOREO",
      linkBoletin: "28",
    },
    {
      id: 11,
      monthBoletin: "FEB",
      nameBoletin: "SEPTIMO INFORME MONITOREO MIyD",
      linkBoletin: "7",
    },
    {
      id: 12,
      monthBoletin: "FEB",
      nameBoletin: "SÉPTIMO INFORME MONITOREO",
      linkBoletin: "13",
    },
    {
      id: 13,
      monthBoletin: "FEB",
      nameBoletin: "OCTAVO INFORME MONITOREO",
      linkBoletin: "14",
    },
    {
      id: 14,
      monthBoletin: "MAR",
      nameBoletin: "DECIMO INFORME MONITOREO MIyD",
      linkBoletin: "8",
    },
    {
      id: 15,
      monthBoletin: "MAR",
      nameBoletin: "NOVENO INFORME MONITOREO MIyD",
      linkBoletin: "9",
    },
    {
      id: 16,
      monthBoletin: "MAR",
      nameBoletin: "OCTAVO INFORME MONITOREO MIyD",
      linkBoletin: "10",
    },
    {
      id: 17,
      monthBoletin: "MAR",
      nameBoletin: "NOVENO INFORME MONITOREO MIyD",
      linkBoletin: "15",
    },
    {
      id: 18,
      monthBoletin: "MAR",
      nameBoletin: "DECIMO INFORME MONITOREO MIyD",
      linkBoletin: "16",
    },
    {
      id: 19,
      monthBoletin: "ABR",
      nameBoletin: "ONCEAVO INFORME MONITOREO MIyD",
      linkBoletin: "17",
    },
    {
      id: 20,
      monthBoletin: "ABR",
      nameBoletin: "DUODECIMO INFORME MONITOREO MIyD",
      linkBoletin: "18",
    },
    {
      id: 21,
      monthBoletin: "ABR",
      nameBoletin: "CUARTO INFORME MONITOREO RyT",
      linkBoletin: "29",
    },
    {
      id: 22,
      monthBoletin: "ABR",
      nameBoletin: "QUINTO INFORME MONITOREO RyT",
      linkBoletin: "30",
    },
    {
      id: 23,
      monthBoletin: "MAY",
      nameBoletin: "DECIMOTERCERO INFORME MONITOREO MIyD",
      linkBoletin: "19",
    },
    {
      id: 24,
      monthBoletin: "MAY",
      nameBoletin: "DECIMOCUARTO INFORME MONITOREO MIyD",
      linkBoletin: "20",
    },
    {
      id: 25,
      monthBoletin: "MAY",
      nameBoletin: "SEXTO INFORME MONITOREO RyT",
      linkBoletin: "31",
    },
    {
      id: 26,
      monthBoletin: "MAY",
      nameBoletin: "SEPTIMO INFORME MONITOREO RyT",
      linkBoletin: "32",
    },
    {
      id: 27,
      monthBoletin: "JUN",
      nameBoletin: "INFORME FINAL MIyD",
      linkBoletin: "21",
    },
    {
      id: 28,
      monthBoletin: "JUN",
      nameBoletin: "INFORME FINAL MONITOREO RyT",
      linkBoletin: "33",
    },
    {
      id: 29,
      monthBoletin: "OCT",
      nameBoletin: "Primer Informe Quincenal",
      linkBoletin: "22",
    },
    {
      id: 30,
      monthBoletin: "OCT",
      nameBoletin: "Segundo Informe Monitoreo IyD",
      linkBoletin: "23",
    },
    {
      id: 31,
      monthBoletin: "OCT",
      nameBoletin: "Tercer Informe Monitoreo IyD",
      linkBoletin: "24",
    },
    {
      id: 32,
      monthBoletin: "OCT",
      nameBoletin: "Primer Informe Radio y Televisión",
      linkBoletin: "34",
    },
    {
      id: 33,
      monthBoletin: "NOV",
      nameBoletin: "Cuarto Informe Monitoreo IyD",
      linkBoletin: "25",
    },
    {
      id: 34,
      monthBoletin: "NOV",
      nameBoletin: "Informe Final Monitoreo IyD",
      linkBoletin: "26",
    },
    {
      id: 35,
      monthBoletin: "NOV",
      nameBoletin: "Segundo Informe Radio y Televisión",
      linkBoletin: "35",
    },
    {
      id: 36,
      monthBoletin: "NOV",
      nameBoletin: "Informe Final Monitoreo RyT",
      linkBoletin: "36",
    },
    {
      id: 37,
      monthBoletin: "DIC",
      nameBoletin: "SEGUNDO INFORME MONITOREO PdG",
      linkBoletin: "37",
    },
    {
      id: 38,
      monthBoletin: "DIC",
      nameBoletin: "TERCER INFORME MONITOREO",
      linkBoletin: "38",
    },
    {
      id: 39,
      monthBoletin: "DIC",
      nameBoletin: "CUARTO INFORME MONITOREO PdG",
      linkBoletin: "39",
    },
    {
      id: 40,
      monthBoletin: "DIC",
      nameBoletin: "QUINTO INFORME MONITOREO PdG",
      linkBoletin: "40",
    },
    {
      id: 41,
      monthBoletin: "DIC",
      nameBoletin: "SEXTO INFORME MONITOREO PdG",
      linkBoletin: "41",
    },
    {
      id: 42,
      monthBoletin: "DIC",
      nameBoletin: "SEPTIMO INFORME MONITOREO PdG",
      linkBoletin: "42",
    },
    {
      id: 43,
      monthBoletin: "DIC",
      nameBoletin: "INFORME FINAL MONITOREO PdG",
      linkBoletin: "43",
    },
    {
      id: 44,
      monthBoletin: "DIC",
      nameBoletin: "Primer Informe Perspectiva de Género",
      linkBoletin: "44",
    },
    {
      id: 45,
      monthBoletin: "DIC",
      nameBoletin: "Segundo Informe Perspectiva de Género",
      linkBoletin: "45",
    },
    {
      id: 46,
      monthBoletin: "DIC",
      nameBoletin: "Informe Final Monitoreo PG",
      linkBoletin: "46",
    },
  ],

  2023: [
    {
      id: 1,
      monthBoletin: "NOV",
      nameBoletin: "Primer Reporte Quincenal",
      linkBoletin: "1",
    },
    {
      id: 2,
      monthBoletin: "DIC",
      nameBoletin: "Segundo Reporte Quincenal",
      linkBoletin: "2",
    },
    {
      id: 3,
      monthBoletin: "DIC",
      nameBoletin: "Tercer Reporte Quincenal",
      linkBoletin: "3",
    },
    {
      id: 4,
      monthBoletin: "DIC",
      nameBoletin: "Cuarto Reporte Quincenal",
      linkBoletin: "4",
    },
  ],
};
