export const dataArt94 = {
  2020: [
    {
      no: "1",
      fraccion: "I",
      titulo: "Índice de los expedientes reservados",
      cumplimiento: "Aplica",
      fundamentoLegal:
        "Artículo. 94 Fracción I de la Ley de Transparencia y acceso a la información para el Estado de Tlaxcala",
      excel1: "LTAIPT2018_A94F1.xlsx",
    },
  ],
  2021: [
    {
      no: "1",
      fraccion: "I",
      titulo: "Índice de los expedientes reservados",
      cumplimiento: "Aplica",
      fundamentoLegal:
        "Artículo. 94 Fracción I de la Ley de Transparencia y acceso a la información para el Estado de Tlaxcala",
      excel1: "LTAIPT_A94F1.xlsx",
      pdf1: "LTAIPT_A94F1.pdf",
    },
  ],
};
