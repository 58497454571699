export const dataInf = {
  2023: [
    {
      id: 1,
      month: "Febrero",
      managerId: null,
    },
    {
      alt: "Constitución de Partidos Políticos Locales",
      link: "1.jpg",
      managerId: 1,
    },
    {
      id: 2,
      month: "Marzo",
      managerId: null,
    },
    {
      alt: "Valores de la democracia",
      link: "2.jpg",
      managerId: 2,
    },
    {
      id: 3,
      month: "Abril",
      managerId: null,
    },
    {
      alt: "Código de Ética",
      link: "3.jpg",
      managerId: 3,
    },
    {
      id: 4,
      month: "Mayo",
      managerId: null,
    },
    {
      alt: "Estudio de participación ciudadana",
      link: "https://participacion-ciudadana.itetlax.org.mx/assets/img/infografias/7.jpg",
      managerId: 4,
    },
    {
      id: 5,
      month: "Junio",
      managerId: null,
    },
    {
      alt: "Código de Ética",
      link: "4.jpg",
      managerId: 5,
    },
    {
      id: 6,
      month: "Julio",
      managerId: null,
    },
    {
      alt: "¿Qué son los partidos politicos?",
      link: "5.jpg",
      managerId: 6,
    },
    {
      id: 7,
      month: "Septiembre",
      managerId: null,
    },
    {
      alt: "Proceso de verificación trianual",
      link: "6.jpg",
      managerId: 7,
    },
    {
      id: 8,
      month: "Octubre",
      managerId: null,
    },
    {
      alt: "Coordinación de Género y No Discriminación del ITE",
      link: "11.jpg",
      managerId: 8,
    },
    {
      id: 9,
      month: "Noviembre",
      managerId: null,
    },
    {
      alt: "En qué consiste el Procedimiento Especial Sancionador",
      link: "12.jpg",
      managerId: 9,
    },
  ],
  2022: [
    {
      id: 1,
      month: "Enero",
      managerId: null,
    },
    {
      alt: "Infografia Nahualt",
      link: "1.jpeg",
      managerId: 1,
    },
    {
      alt: "Avisos de privacidad",
      link: "2.jpeg",
      managerId: 1,
    },
    {
      id: 2,
      month: "Febrero",
      managerId: null,
    },
    {
      alt: "Constitución de Partidos Políticos Locales",
      link: "3.jpg",
      managerId: 2,
    },
    {
      alt: "Plebiscito 1",
      link: "4.jpeg",
      managerId: 2,
    },
    {
      alt: "Plebiscito 2",
      link: "5.jpeg",
      managerId: 2,
    },
    {
      alt: "¿Como nos identificamos'",
      link: "6.jpeg",
      managerId: 2,
    },
    {
      alt: "Infografía 1",
      link: "7.jpeg",
      managerId: 2,
    },
    {
      id: 3,
      month: "Marzo",
      managerId: null,
    },
    {
      alt: "Sesiones de Consejo General",
      link: "8.jpg",
      managerId: 3,
    },
    {
      id: 4,
      month: "Junio",
      managerId: null,
    },
    {
      alt: "Día Nacional de la Lengua de Señas Mexicana",
      link: "9.jpg",
      managerId: 4,
    },
    {
      id: 5,
      month: "Julio",
      managerId: null,
    },
    {
      alt: "Voz ciudadana en el cabildo",
      link: "10.jpg",
      managerId: 5,
    },
  ],
  2021: [
    {
      id: 1,
      month: "Enero",
      managerId: null,
    },
    {
      alt: "Transparencia",
      link: "1.jpg",
      managerId: 1,
    },
    {
      id: 2,
      month: "Febrero",
      managerId: null,
    },
    {
      alt: "Infografia 1",
      link: "2.jpg",
      managerId: 2,
    },
    {
      alt: "Infografía 2",
      link: "3.jpg",
      managerId: 2,
    },
    {
      id: 3,
      month: "Marzo",
      managerId: null,
    },
    {
      alt: "Dirección de Asuntos Jurídicos",
      link: "4.jpg",
      managerId: 3,
    },
    {
      alt: "Contraloría General del ITE",
      link: "5.jpeg",
      managerId: 3,
    },
    {
      id: 4,
      month: "Abril",
      managerId: null,
    },
    {
      alt: "Derechos ARCO",
      link: "6.jpg",
      managerId: 4,
    },
    {
      alt: "Medidas sanitarias para el voto seguro",
      link: "7.jpg",
      managerId: 4,
    },
    {
      alt: "Referéndum",
      link: "8.jpeg",
      managerId: 4,
    },
    {
      alt: "Referéndum Nahuatl",
      link: "9.jpeg",
      managerId: 4,
    },
    {
      alt: "Referéndum",
      link: "10.jpeg",
      managerId: 4,
    },
    {
      alt: "Referéndum Nahuatl",
      link: "11.jpeg",
      managerId: 4,
    },
    {
      id: 5,
      month: "Mayo",
      managerId: null,
    },
    {
      alt: "Datos PREP",
      link: "12.jpg",
      managerId: 5,
    },
    {
      alt: "Referendum",
      link: "13.jpeg",
      managerId: 5,
    },
    {
      alt: "Referendum Nahualt",
      link: "14.jpeg",
      managerId: 5,
    },
    {
      id: 6,
      month: "Junio",
      managerId: null,
    },
    {
      alt: "Proceso Electoral Local Ordinario 2020-2021",
      link: "15.jpeg",
      managerId: 6,
    },
    {
      id: 7,
      month: "Julio",
      managerId: null,
    },
    {
      alt: "Proceso Electoral Local Ordinario 2020-2021 Medios de Comunicación",
      link: "16.png",
      managerId: 7,
    },
    {
      alt: "Plebiscito Nahualt",
      link: "17.png",
      managerId: 7,
    },
    {
      id: 8,
      month: "Agosto",
      managerId: null,
    },
    {
      alt: "Financiamiento Público",
      link: "18.jpg",
      managerId: 8,
    },
    {
      alt: "Plebiscito Nahualt",
      link: "19.png",
      managerId: 8,
    },
    {
      id: 10,
      month: "Octubre",
      managerId: null,
    },
    {
      alt: "Secretaría Ejecutiva",
      link: "20.jpg",
      managerId: 10,
    },
    {
      alt: "Etapas de un Documento de Archivo",
      link: "21.jpg",
      managerId: 10,
    },
    {
      alt: "Acervo Documental",
      link: "22.jpg",
      managerId: 10,
    },
    {
      alt: "Ciclo vital de un documento",
      link: "23.jpg",
      managerId: 10,
    },
    {
      id: 11,
      month: "Noviembre",
      managerId: null,
    },
    {
      alt: "Violencia Política Contral las Mujeres en Razón de Género",
      link: "24.jpg",
      managerId: 11,
    },
    {
      alt: "Violencia Política",
      link: "25.jpg",
      managerId: 11,
    },
  ],
  2020: [
    {
      id: 1,
      month: "Enero",
      managerId: null,
    },
    {
      alt: "Día de la No Violencia Escolar",
      link: "1.jpg",
      managerId: 1,
    },
    {
      id: 2,
      month: "Febrero",
      managerId: null,
    },
    {
      alt: "Información Procesos Electorales",
      link: "2.jpg",
      managerId: 2,
    },
    {
      id: 3,
      month: "Marzo",
      managerId: null,
    },
    {
      alt: "Contraloría General del ITE LIPEET Artpiculo 85",
      link: "3.jpg",
      managerId: 3,
    },
    {
      id: 4,
      month: "Abril",
      managerId: null,
    },
    {
      alt: "Infografía Nahualt 1",
      link: "4.jpg",
      managerId: 4,
    },
    {
      alt: "Infografía Nahualt 2",
      link: "5.jpg",
      managerId: 4,
    },
    {
      alt: "Atribuciones del Titular del Área Técnica de Informática",
      link: "6.jpg",
      managerId: 4,
    },
    {
      id: 5,
      month: "Mayo",
      managerId: null,
    },
    {
      alt: "Información Proceso Electoral 2021",
      link: "7.jpg",
      managerId: 5,
    },
    {
      id: 6,
      month: "Junio",
      managerId: null,
    },
    {
      alt: "Oficialía Electoral",
      link: "8.jpg",
      managerId: 6,
    },
    {
      id: 7,
      month: "Julio",
      managerId: null,
    },
    {
      alt: "Datos Personales",
      link: "9.jpg",
      managerId: 7,
    },
    {
      alt: "Micrositio COVID",
      link: "10.jpeg",
      managerId: 7,
    },
    {
      id: 8,
      month: "Agosto",
      managerId: null,
    },
    {
      alt: "Que hace la Presidencia del ITE",
      link: "11.jpg",
      managerId: 8,
    },
    {
      alt: "Que funciones tiene la Presidencia del ITE",
      link: "12.jpg",
      managerId: 8,
    },
    {
      id: 9,
      month: "Septiembre",
      managerId: null,
    },
    {
      alt: "Atribuciones de Prerrogativas, Administración y Fiscalización",
      link: "13.jpg",
      managerId: 9,
    },
    {
      id: 10,
      month: "Octubre",
      managerId: null,
    },
    {
      alt: "Datos Personales Nahualt 1",
      link: "14.jpg",
      managerId: 10,
    },
    {
      alt: "Datos Personales Nahualt 2",
      link: "15.jpg",
      managerId: 10,
    },
    {
      alt: "Día Internacional de la No Violencia",
      link: "16.jpg",
      managerId: 10,
    },
    {
      alt: "Atribuciones de la Dirección de Asuntos Jurídicos",
      link: "17.jpg",
      managerId: 10,
    },
    {
      id: 11,
      month: "Noviembre",
      managerId: null,
    },
    {
      alt: "Integración del COTAPREP",
      link: "18.jpg",
      managerId: 11,
    },
    {
      alt: "Datos Proceso Electoral Local Ordinario 2020 - 2021",
      link: "19.jpg",
      managerId: 11,
    },
    {
      alt: "Vota, tú decides",
      link: "20.jpg",
      managerId: 11,
    },
    {
      id: 12,
      month: "Diciembre",
      managerId: null,
    },
    {
      alt: "Día Internacional de las personas con discapacidad",
      link: "21.jpg",
      managerId: 12,
    },
    {
      alt: "Manifestaciones de intención",
      link: "22.jpg",
      managerId: 12,
    },
    {
      alt: "Manifestaciones de intención aprobadas",
      link: "23.jpg",
      managerId: 12,
    },
    {
      alt: "Día internacional de los derechos humanos",
      link: "24.jpg",
      managerId: 12,
    },
  ],
  2019: [
    { id: 1, month: "Infografías", managerId: null },
    {
      alt: "Día internacional de la LEngua Materna",
      link: "1.jpg",
      managerId: 1,
    },
    {
      alt: "Datos Personales",
      link: "2.jpg",
      managerId: 1,
    },
    {
      alt: "Datos Personales 2",
      link: "3.jpg",
      managerId: 1,
    },
    {
      alt: "Datos Personales 3",
      link: "4.jpg",
      managerId: 1,
    },
    {
      id: 3,
      month: "Marzo",
      managerId: null,
    },
    {
      alt: "Atribuciones del Titular del Área Técnica de Informática",
      link: "5.jpg",
      managerId: 3,
    },
    {
      id: 4,
      month: "Abril",
      managerId: null,
    },
    {
      alt: "Los mecanismos de paticipación ciudadana",
      link: "6.jpg",
      managerId: 4,
    },
    {
      alt: "Que es el referendum",
      link: "7.jpg",
      managerId: 4,
    },
    {
      alt: "Referendum",
      link: "8.jpg",
      managerId: 4,
    },
    {
      alt: "Referendum 2",
      link: "9.jpg",
      managerId: 4,
    },
    {
      alt: "Que es la participación ciudadana",
      link: "10.jpg",
      managerId: 4,
    },
    {
      alt: "Quienes pueden participar en los procesos de consulta ciudadana",
      link: "11.jpg",
      managerId: 4,
    },
    {
      alt: "Obligaciones del ITE en materia de consulta ciudadana",
      link: "12.jpg",
      managerId: 4,
    },
    {
      alt: "Que es la consulta popular",
      link: "13.jpg",
      managerId: 4,
    },
    {
      alt: "La consulta popular",
      link: "14.jpg",
      managerId: 4,
    },
    {
      alt: "Quien puede solicitar una consulta popular",
      link: "15.jpg",
      managerId: 4,
    },
    {
      id: 6,
      month: "Junio",
      managerId: null,
    },
    {
      alt: "Elecciones escolares",
      link: "16.jpg",
      managerId: 6,
    },
    {
      id: 7,
      month: "Julio",
      managerId: null,
    },
    {
      alt: "Convenios celebrados por el ITE",
      link: "17.jpg",
      managerId: 7,
    },
    {
      id: 8,
      month: "Agosto",
      managerId: null,
    },
    {
      alt: "Financiamiento público para partidos políticos 2020",
      link: "18.jpg",
      managerId: 8,
    },
    {
      id: 9,
      month: "Septiembre",
      managerId: null,
    },
    {
      alt: "Comité técnico de archivos",
      link: "19.jpg",
      managerId: 9,
    },
    {
      alt: "Día Internacional del Derecho a Saber",
      link: "20.jpg",
      managerId: 9,
    },
    {
      id: 10,
      month: "Octubre",
      managerId: null,
    },
    {
      alt: "Que es el voto",
      link: "21.jpg",
      managerId: 10,
    },
    {
      id: 11,
      month: "Noviembre",
      managerId: null,
    },
    {
      alt: "Calendario de multas",
      link: "22.jpg",
      managerId: 11,
    },
    {
      id: 12,
      month: "Diciembre",
      managerId: null,
    },
    {
      alt: "Que es la UTCE",
      link: "23.jpg",
      managerId: 12,
    },
  ],
};
